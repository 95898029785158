.react-datepicker-host {
  display: block;
  position: relative;
  width: 100%;

  .react-datepicker-wrapper {
    display: block;
    background-color: transparent;
    &:after {
      content: "\e83a";
      font-family: "feather";
      position: absolute;
      top: 1.1rem;
      // top: 1.5rem;
      right: 1.5rem;
      z-index: 1;
    }

    .react-datepicker__input-container {
      display: block;
      width: 100%;
      z-index: 2;
      input {
        background-color: transparent;
        cursor: pointer;
        height: 4rem;
        // height: 4.5rem;
      }
    }
  }

  .react-datepicker-popper {
    transform: none !important;
    top: 0;
    margin-top: 0 !important;
    z-index: 99;

    .react-datepicker {
      border-color: $border-color;
      border-radius: 0;
      filter: drop-shadow(0 0 1.45rem rgba(0, 0, 0, 0.1));

      .react-datepicker__triangle {
         display: none;
      }

      .react-datepicker__navigation {
        display: block;
        background-color: transparent;
        top: 1.6rem;

        &--previous::before {
          position: absolute;
          transform: rotate(90deg);
          left: 1rem;
        }
        &--next::before {
          position: absolute;
          transform: rotate(-90deg);
          right: 1rem;
        }
      }

      .react-datepicker__month-container {
        padding: 0;

        .react-datepicker__header {
          height: 4.4rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          padding: 2rem;
          border-color: $border-color;

          .react-datepicker__current-month {
            font-size: 1.4rem;
            letter-spacing: 0;
            line-height: 2rem;
            color: $datepicker-text-color;
            font-family: "Ubuntu";
            font-weight: 700;
            text-align: center;
          }

          .react-datepicker__day-names {
            .react-datepicker__day-name {
              display: none;
            }
          }

          .react-datepicker__header__dropdown {
            .react-datepicker__month-dropdown-container .react-datepicker__month-select,
            .react-datepicker__year-dropdown-container .react-datepicker__year-select {
              background-color: transparent;
              font-family: "Ubuntu";
              color: $datepicker-text-color;
              border: 0;
              outline: 0.1rem solid $border-color;
              margin: 0 0.5rem;
            }
          }
        }

        .react-datepicker__month {
          margin: 0;
          padding: 2rem;
        }

        .react-datepicker__day {
          width: 2rem;
          height: 2rem;
          font-size: 1.2rem;
          padding: 0.3rem;
          letter-spacing: 0;
          line-height: 1.5rem;
          color: $grey-text-color;
          font-family: "Ubuntu";
          font-weight: 400;
          text-align: center;
          border-radius: 0;
          outline: none;

          &--keyboard-selected {
            background-color: $datepicker-selected-background;
            color: $white-color;
          }

          &--outside-month {
            opacity: 0.6;
          }

          &--disabled {
            color: rgba($datepicker-text-color, 0.2);
          }

          &--today {
            position: relative;

            &:after {
              position: absolute;
              content: '';
              width: 0.3rem;
              height: 0.3rem;
              background-color: $theme-second-color;
              border-radius: 50%;
              display: flex;
              bottom: 0;
              left: calc(50% - 0.1rem);
            }
          }
        }
      }
    }
  }

  &.select-month-year {
    .react-datepicker-popper {
      .react-datepicker {
        .react-datepicker__month-container {
          .react-datepicker__header {
            height: 7.5rem;
          }
          .react-datepicker__header__dropdown {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
