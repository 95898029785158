@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

$header-height: 7rem;
$menu-width: 25rem;
$menu-collapsed-width: 7rem;
$button-height: 3.5rem;

// default theme color
$primary-color: #4886ff;
$warning-color: #ffa628;
$danger-color: #ee3434;
$success-color: #00db5d;
$purple-color: #7759de;
$info-color: #00ACC1;
$secondary-color: #748892;
$default-color: #d6d6d6;
$light-color: #ECEFF1;
$dark-color: #263238;
$inverse-color: $dark-color;
$theme-border: #e2e5e8;
$error-block-color: #bc5a45;

//theme
$theme-color: #ff585f;
$theme-second-color: #334048;
$theme-color-50-percent: lighten($theme-color, 50%);

// base
$base-text-color: #535763;
$base-background: #f4f4f4;

$insurance-list-hover-color: rgba(0, 0, 0, 0.075);
$navigation-menu-color: #242e3e;
$view-title-color: #050708;
$snippet-bg-color: #cccccc;
$close-modal-color:#747474;
// card
$card-border-bottom-color: #ced4da;

// table
$table-border-color: #e2e5e8;
$table-head-background-color: #f6f7fb;
$table-row-background-color: #f9fbff;

// form controls
$input-text-color: #495057;
$input-border-color: #ced4da;
$datepicker-border-color: #d2d8dd;
$datepicker-text-color: #6c757d;
$datepicker-text-color-disabled: rgba(108, 117, 125, 0.2);
$datepicker-filter: rgba(0, 0, 0, 0.1);
$datepicker-selected-background: #334048;
$select-box-shadow: rgba(0, 123, 255 ,0.25);
$control-group-background: #f9fbff;

$calendar-tabs-background: #ebebeb;
$btn-action-color: #909090;
$btn-action-border-color: #d2d2d2;
$snippet-border-color: #c8c8c8;

// basic colors:
$white-color: #fff;
$black-color: #000;
$grey-text-color: #666;

// breakpoints:
$breakpoint-xsmall: 480px;
$breakpoint-small: 768px;
$breakpoint-medium: 992px;
$breakpoint-large: 1024px;
$breakpoint-xlarge: 1280px;
$breakpoint-xxlarge: 1400px;
$breakpoint-full: 1600px;

// font-sizes:
$xxs-font-size: 1rem;
$xs-font-size: 1.1rem;
$small-font-size: 1.2rem;
$small-base-font-size: 1.3rem;
$base-font-size: 1.4rem;
$base-m-font-size: 1.6rem;
$base-ml-font-size: 1.7rem;
$m-font-size: 1.8rem;
$ml-font-size: 2rem;
$l-font-size: 2.2rem;
$x-font-size: 2.4rem;
$xl-font-size: 2.6rem;
$xll-font-size: 2.8rem;
$xxl-font-size: 3.2rem;
$xxxl-font-size: 3.5rem;
$xxxxl-font-size: 4.5rem;

// datepicker checkbox colors:
$checked-background: #E0F7EF;
$checked-color: #35E4A1;
$checkbox-background: #f9f9f9;
$checkbox-color: #535763;

$form-font: 'Work Sans', sans-serif;

