table.data-table {
  border-collapse: collapse;
  width: 100%;

  &.no-hover {
    tbody {
      tr {
        &:hover {
          &:nth-child(2n) {
            background: $table-row-background-color;
          }
          &:nth-child(2n+1) {
            background: initial;
          }
        }
      }
    }
  }

  thead {
    tr {
      height: 5.5rem;
      background-color: $calendar-tabs-background;

      th {
        padding: 1rem;
        min-width: 8rem;
        font-weight: 600;
        font-size: $base-font-size;
        color: $view-title-color;
        vertical-align: middle;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(2n),
      &:hover {
        background: $base-background;
      }

      td {
        vertical-align: middle;
        padding: 1rem;
        color: $grey-text-color;

        a {
          &:hover {
            text-decoration: none;
            font-weight: 500;
            color: $theme-color;
          }
        }
      }
    }
  }
}
