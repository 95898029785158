.checkbox-container {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;

  .checkbox-wrapper {
    display: inline-flex;
    align-items: center;
    position: relative;
    font-size: $base-font-size;
    padding-left: 2rem;
    line-height: 1.3;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $grey-text-color;
    font-weight: 400;

    .checkbox-input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkbox-input:checked ~ .checkbox-checkmark:after {
      display: block;
    }

    .checkbox-input:checked ~ .checkbox-custom-label .checkbox-checkmark:after {
      display: block;
    }

    .checkbox-checkmark {
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-appearance: none;
      position: absolute;
      height: 1.5rem;
      width: 1.5rem;
      margin: 0;
      top: 0.2rem;
      left: 0;
      border: thin solid $border-color;
      background-color: $white-color;
      transition: all 0.3s ease-in-out;
      outline: none;

      &:after {
        position: absolute;
        display: none;
        padding: 0.4rem;
        font-family: 'feather';
        content: "\e83f";
        color: $theme-color;
      }

      &:checked {
        color: $secondary-color;
      }
    }

    .checkbox-label {
      a {
        font-size: $base-font-size;
        color: $grey-text-color;
      }
    }

    .checkbox-custom-label {
      a {
        font-size: $base-font-size;
        color: $grey-text-color;
      }
    }
  }
}
