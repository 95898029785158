@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");

/*! dialog layout */
.e-dialog {
  border: none;
  border-radius: 2px;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;

  .e-dlg-header-content {
    border-radius: 1px 1px 0 0;
    line-height: 30px;
    & + .e-dlg-content {
      padding-top: 0;
    }
  }

  &.e-popup {
    width: 100%;
  }

  &.e-dlg-resizable {
    -ms-touch-action: none;
    touch-action: none;
  }
}

.e-dialog .e-btn .e-btn-icon.e-icon-dlg-close {
  font-size: 12px;
  width: auto;
}

.e-dialog .e-dlg-header {
  display: block;
  font-size: 18px;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: top;
  white-space: nowrap;
  width: 80%;
}

.e-dialog .e-dlg-modal {
  position: fixed;
}

.e-dialog .e-scroll-disabled {
  overflow: hidden !important;
}

.e-dialog .e-dlg-content {
  display: block;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 13px;
  font-weight: normal;
  line-height: normal;
  overflow: auto;
  overflow-x: hidden;
}

.e-dialog .e-footer-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top: none;
  bottom: 0;
  display: block;
  right: 0;
  width: 100%;
}

.e-dialog .e-footer-content {
  text-align: right;
}

.e-dialog .e-resize-handle {
  height: 15px;
  position: absolute;
  width: 15px;
}

.e-dialog .e-resize-handle.e-south-east {
  bottom: 0;
  cursor: nwse-resize;
  right: 0;
}

.e-dialog .e-resize-handle.e-south-west {
  bottom: 0;
  cursor: nesw-resize;
  left: 0;
  transform: rotate(90deg);
}

.e-dialog .e-resize-handle.e-north-east {
  cursor: nesw-resize;
  right: 0;
  top: 0;
  transform: rotate(-90deg);
}

.e-dialog .e-resize-handle.e-north-west {
  cursor: nwse-resize;
  left: 0;
  top: 0;
  transform: rotate(180deg);
}

.e-dialog .e-south,
.e-dialog .e-north,
.e-dialog .e-east,
.e-dialog .e-west {
  background-color: transparent;
  background-repeat: repeat;
  overflow: visible;
  position: absolute;
}

.e-dialog .e-east,
.e-dialog .e-west {
  cursor: ew-resize;
}

.e-dialog .e-south,
.e-dialog .e-north {
  cursor: ns-resize;
}

.e-dialog.e-blazor-hidden {
  left: 0;
  position: absolute;
}

.e-bigger.e-dialog .e-dlg-header-content,
*.e-bigger .e-dialog .e-dlg-header-content {
  padding: 24px 24px 20px;
}

.e-bigger.e-dialog .e-dlg-header,
*.e-bigger .e-dialog .e-dlg-header {
  font-size: 18px;
}

.e-bigger.e-dialog .e-dlg-content,
*.e-bigger .e-dialog .e-dlg-content {
  font-size: 13px;
  padding: 24px;
}

.e-bigger.e-dialog .e-footer-content,
*.e-bigger .e-dialog .e-footer-content {
  padding: 8px;
}

.e-bigger.e-dialog .e-footer-content .e-btn,
*.e-bigger .e-dialog .e-footer-content .e-btn {
  margin-left: 8px;
}

.e-bigger.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn,
*.e-bigger .e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
  bottom: 3px;
  height: 36px;
  left: 6px;
  width: 36px;
}

.e-bigger.e-dialog .e-btn .e-btn-icon.e-icon-dlg-close,
*.e-bigger .e-dialog .e-btn .e-btn-icon.e-icon-dlg-close {
  font-size: 12px;
  width: auto;
}

.e-bigger.e-rtl .e-footer-content .e-btn,
.e-bigger .e-rtl .e-footer-content .e-btn {
  margin-left: 0;
  margin-right: 8px;
}

.e-bigger .e-dlg-header-content + .e-dlg-content {
  padding-top: 0;
}

.e-dialog .e-dlg-header-content {
  border-bottom: none;
  padding: 18px;
}

.e-dialog .e-dlg-content {
  padding: 18px;
}

.e-dialog .e-footer-content {
  padding: 8px;
}

.e-dialog .e-footer-content .e-btn {
  margin-left: 6px;
}

.e-rtl .e-footer-content .e-btn {
  margin-right: 6px;
}

.e-dialog.e-draggable .e-dlg-header-content {
  cursor: move;
}

.e-dialog {
  max-height: 98%;
  max-width: 100%;
  min-width: 240px;
}

.e-rtl .e-footer-content .e-btn {
  margin-left: 0;
}

.e-rtl .e-footer-content {
  text-align: left;
}

.e-rtl .e-footer-content {
  text-align: left;
}

.e-dialog.e-rtl .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
  float: left;
}

.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
  background-color: transparent;
  border-color: transparent;
  border-radius: 50%;
  bottom: 0;
  float: right;
  height: 30px;
  left: 6px;
  position: relative;
  width: 30px;
}

.e-rtl.e-dialog .e-resize-handle.e-south-west {
  bottom: 2px;
  cursor: sw-resize;
  left: 2px;
  transform: rotate(90deg);
}

.e-dlg-target.e-scroll-disabled {
  overflow: hidden !important;
}

.e-dlg-overlay {
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  transition: opacity .15s linear;
  width: 100%;
}

.e-dlg-overlay.e-fade {
  opacity: 0;
}

.e-dlg-overflow-hidden {
  overflow: auto;
}

.e-dlg-fullscreen {
  height: 100% !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
}

.e-popup.e-popup-open.e-dialog {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-dlg-container {
  -ms-flex-align: start;
  align-items: flex-start;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.e-dlg-center-center {
  -webkit-align-items: center;
  -webkit-justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.e-dlg-left-center {
  -webkit-align-items: center;
  -webkit-justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.e-dlg-right-center {
  -webkit-align-items: center;
  -webkit-justify-content: flex-end;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.e-dlg-left-top {
  -webkit-align-items: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.e-dlg-right-top {
  -webkit-align-items: flex-start;
  -webkit-justify-content: flex-end;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.e-dlg-center-top {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
}

.e-dlg-left-bottom {
  -webkit-align-items: flex-end;
  -webkit-justify-content: flex-start;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.e-dlg-right-bottom {
  -webkit-align-items: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.e-dlg-center-bottom {
  -webkit-align-items: center;
  -webkit-justify-content: flex-end;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.e-dialog .e-btn.e-dlg-closeicon-btn:hover,
.e-dialog .e-btn.e-dlg-closeicon-btn:focus,
.e-dialog .e-btn.e-dlg-closeicon-btn:active {
  background-color: #e0e0e0;
  border-color: transparent;
  box-shadow: 0 0 0 transparent;
}

.e-content-placeholder.e-dialog.e-placeholder-dialog {
  background-size: 400px 210px;
  min-height: 210px;
}

.e-bigger .e-content-placeholder.e-dialog.e-placeholder-dialog,
.e-bigger.e-content-placeholder.e-dialog.e-placeholder-dialog {
  background-size: 400px 220px;
  min-height: 220px;
}

@media (min-width: 768px) {
  .e-alert-dialog.e-dialog.e-popup,
  .e-confirm-dialog.e-dialog.e-popup {
    margin: 30px auto;
    width: 600px;
  }
}

@media (max-width: 768px) {
  .e-alert-dialog.e-dialog.e-popup,
  .e-confirm-dialog.e-dialog.e-popup {
    margin: 30px auto;
    width: auto;
  }
}

.e-dlg-ref-element {
  display: none;
}

.e-dialog {
  background-color: #fff;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.e-dlg-overlay {
  background-color: #383838;
}

.e-footer-content {
  background-color: #fff;
}

.e-dlg-header,
.e-dlg-header * {
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: normal;
}

.e-dlg-content {
  color: rgba(0, 0, 0, 0.87);
}

.e-device .e-dlg-content,
.e-device .e-dlg-content * {
  font-size: 14px;
}

.e-dlg-header-content {
  background-color: #fff;
}

.e-dlg-content {
  background-color: #fff;
}

.e-icon-dlg-close {
  color: #000;
}

.e-dialog .e-btn.e-dlg-closeicon-btn:hover span {
  color: #000;
}

.e-dialog .e-btn.e-dlg-closeicon-btn:active span,
.e-dialog .e-btn.e-dlg-closeicon-btn:focus span {
  border-radius: 50%;
  color: #000;
  opacity: 1;
}

.e-icon-dlg-close:active {
  border-radius: 50%;
  color: #000;
  opacity: 1;
}

.e-icon-dlg-close:hover {
  color: #000;
}

.e-dlg-header-content .e-dlg-closeicon-btn:hover {
  background-color: transparent;
}

.e-dlg-header-content .e-dlg-closeicon-btn:active {
  background-color: transparent;
}

.e-south-east {
  color: #000;
}

.e-rtl .e-south-east {
  color: transparent;
}

.e-rtl .e-south-west {
  color: #000;
}

.e-south-west,
.e-north-east,
.e-north-west {
  color: transparent;
}

/*! popup layout */
.e-popup {
  height: auto;
  position: absolute;
  width: auto;
  z-index: 1000;

  &.e-popup-open {
    display: block;
  }

  &.e-popup-close {
    display: none;
  }
}



.e-tooltip-close::before {
  content: '\e7e9';
  font-size: 16px;
}

.e-arrow-tip-inner.e-tip-right::before {
  content: '\e848';
}

.e-arrow-tip-inner.e-tip-top::before {
  content: '\e918';
}

.e-arrow-tip-inner.e-tip-bottom::before {
  content: '\e919';
}

.e-arrow-tip-inner.e-tip-left::before {
  content: '\e84b';
}

/*! tooltip layout */
.e-tooltip-wrap {
  max-width: 350px;
  min-width: 30px;
  padding: 0;
  position: absolute;
  visibility: visible;

  .e-arrow-tip {
    overflow: hidden;
    position: absolute;

    &.e-tip-bottom {
      height: 8px;
      left: 50%;
      top: 100%;
      width: 16px;
    }

    &.e-tip-top {
      height: 8px;
      left: 50%;
      top: -9px;
      width: 16px;
    }

    &.e-tip-left {
      height: 16px;
      left: -9px;
      top: 48%;
      width: 8px;
    }

    &.e-tip-right {
      height: 16px;
      left: 100%;
      top: 50%;
      width: 8px;
    }
  }

  .e-tooltip-close {
    cursor: pointer;
    float: right;
    position: absolute;
    right: -9px;
    top: -9px;
    z-index: inherit;
  }

  .e-tip-content {
    background-color: inherit;
    height: 100%;
    line-height: 16px;
    overflow-wrap: break-word;
    overflow-x: hidden;
    padding: 3px 6px;
    position: relative;
    white-space: normal;
    width: 100%;
    word-break: break-word;
    z-index: 1;
  }

}

/*! Bigger Style */
.e-bigger .e-tooltip-wrap .e-tip-content,
.e-tooltip-wrap.e-bigger .e-tip-content {
  line-height: 20px;
  padding: 5px 8px;
}

.e-bigger {
  .e-tooltip-wrap {
    .e-tip-content {
      line-height: 20px;
      padding: 5px 8px;
    }

    &.e-bigger {
      .e-tip-content {
        line-height: 20px;
        padding: 5px 8px;
      }
    }
  }
}

/*! Tooltip theme */
.e-tooltip-wrap {
  border-radius: 2px;
  filter: none;
  opacity: 0.9;

  .e-arrow-tip-outer {
    height: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 0;

    &.e-tip-bottom {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #616161;
    }

    &.e-tip-top {
      border-bottom: 8px solid #616161;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }

    &.e-tip-left {
      border-bottom: 8px solid transparent;
      border-right: 8px solid #616161;
      border-top: 8px solid transparent;
    }

    &.e-tip-right {
      border-bottom: 8px solid transparent;
      border-left: 8px solid #616161;
      border-top: 8px solid transparent;
    }
  }

  .e-arrow-tip-inner {
    height: 0;
    position: absolute;
    width: 0;
    z-index: 10;

    &.e-tip-right,
    &.e-tip-left,
    &.e-tip-bottom,
    &.e-tip-top {
      color: #616161;
      font-family: 'e-icons';
      font-size: 16px;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      line-height: 1;
      text-transform: none;
    }
  }

  .e-tooltip-close {
    background-color: #fff;
    border-color: transparent;
    border-radius: 8px;
    color: #616161;
  }

  .e-tooltip-close:hover {
    background-color: #fff;
    color: #616161;
  }

  .e-tip-content {
    color: #fff;
    font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
    font-size: 11px;
  }

  &.e-popup {
    background-color: #616161;
    border: 1px solid #616161;
  }
}
