.slots-container {
  display: flex;
  flex-direction: column;

  .slots-label {
    display: block;
    width: 100%;
    text-align: left;
    font-weight: 700;
    margin: 1rem 0;
    color: $grey-text-color;
    font-size: $base-font-size;
    min-height: 1.7rem;
  }

  .slots {
    display: flex;
    flex-wrap: wrap;
  }
}
