@font-face {
  font-family: 'rsuite-icon-font';
  src: url('https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.ttf') format('truetype'), url('https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.rs-uploader-trigger input[type='file'] {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
}
.rs-uploader-trigger-disabled {
  opacity: 0.3;
}
.rs-uploader-trigger-disabled .rs-uploader-trigger-btn {
  cursor: not-allowed !important;
}
.rs-uploader-file-item-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.rs-uploader-picture .rs-uploader-file-item-disabled .rs-uploader-file-item-status {
  cursor: not-allowed;
}
.rs-uploader-file-item-disabled .rs-uploader-file-item-btn-remove {
  display: none;
}
.rs-uploader-file-item-icon-reupload {
  cursor: pointer;
  margin-left: 10px;
  color: #575757;
}
.rs-uploader-file-item-icon-reupload::before {
  font-family: 'rsuite-icon-font';
  content: '\ea1f';
  font-style: normal;
}
.rs-uploader-file-item-icon-reupload::before,
.rs-uploader-file-item-icon-reupload::before:hover {
  text-decoration: none;
}
.rs-uploader-text .rs-uploader-file-items {
  margin-top: 10px;
}
.rs-uploader-text .rs-uploader-file-item {
  padding: 8px 30px 8px 36px;
  position: relative;
  -webkit-transition: 0.3s linear background-color;
  transition: 0.3s linear background-color;
}
.rs-uploader-text .rs-uploader-file-item:hover {
  background-color: #f7f7fa;
}
.rs-uploader-text .rs-uploader-file-item-icon-wrapper {
  position: absolute;
  display: inline-block;
  top: 8px;
  left: 12px;
  width: 20px;
  text-align: center;
}
.rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon {
  width: 18px;
  height: 18px;
}
.rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
.rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  width: 18px;
  height: 18px;
}
.rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
.rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  border-radius: 50%;
}
.rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before {
  border: 3px solid rgba(247, 247, 250, 0.8);
}
.rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  border-width: 3px;
  border-style: solid;
  border-color: #a6a6a6 transparent transparent;
  -webkit-animation: loaderSpin 0.6s infinite linear;
  animation: loaderSpin 0.6s infinite linear;
}
.rs-uploader-text .rs-uploader-file-item-icon::before {
  font-family: 'rsuite-icon-font';
  content: '\ea10';
  font-style: normal;
  color: #575757;
  font-size: 16px;
  line-height: 1.25;
}
.rs-uploader-text .rs-uploader-file-item-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.rs-uploader-text .rs-uploader-file-item-content,
.rs-uploader-text .rs-uploader-file-item-content > a {
  color: #575757;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.rs-uploader-text .rs-uploader-file-item-content > a:hover,
.rs-uploader-text .rs-uploader-file-item-content > a:focus {
  text-decoration: none;
}
.rs-uploader-text .rs-uploader-file-item-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.rs-uploader-text .rs-uploader-file-item-size {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #8e8e93;
  padding: 0 8px;
}
.rs-uploader-text .rs-uploader-file-item-status {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  float: right\9;
  display: table\9;
}
.rs-uploader-text .rs-uploader-file-item-status > a,
.rs-uploader-text .rs-uploader-file-item-status > a:hover,
.rs-uploader-text .rs-uploader-file-item-status > a:focus,
.rs-uploader-text .rs-uploader-file-item-status > a:active {
  text-decoration: none;
}
.rs-uploader-text .rs-uploader-file-item-btn-remove {
  position: absolute;
  font-size: 12px;
  top: 12px;
  right: 12px;
  color: #8e8e93;
  cursor: pointer;
  font-family: 'rsuite-icon-font';
  font-style: normal;
  line-height: 1;
}
.rs-uploader-text .rs-uploader-file-item-btn-remove:hover,
.rs-uploader-text .rs-uploader-file-item-btn-remove:focus {
  text-decoration: none;
}
.rs-uploader-text .rs-uploader-file-item-btn-remove::before {
  font-family: 'rsuite-icon-font';
  font-style: normal;
  line-height: 1;
  content: '\f00d';
}
.rs-uploader-text .rs-uploader-file-item-btn-remove [aria-hidden] {
  display: none;
}
.rs-uploader-text .rs-uploader-file-item-panel {
  position: relative;
}
.rs-uploader-text .rs-uploader-file-item-progress {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.rs-uploader-text .rs-uploader-file-item-progress-bar {
  height: 2px;
  background-color: $theme-color;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
.rs-uploader-text .rs-uploader-file-item-has-error .rs-uploader-file-item-content,
.rs-uploader-text .rs-uploader-file-item-has-error .rs-uploader-file-item-title {
  color: $danger-color;
}
.rs-uploader-picture {
  // display: inline-block;
  display: flex;
  flex-direction: column;
}
.rs-uploader-picture::before,
.rs-uploader-picture::after {
  content: ' ';
  display: table;
}
.rs-uploader-picture::after {
  clear: both;
}
.rs-uploader-picture::before,
.rs-uploader-picture::after {
  content: ' ';
  display: table;
}
.rs-uploader-picture::after {
  clear: both;
}
.rs-uploader-picture .rs-uploader-trigger-btn {
  display: inline-block;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* stylelint-disable */
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  /* stylelint-enable */
  overflow: hidden;
  position: relative;
  -webkit-transition: color 0.2s linear, background-color 0.3s linear;
  transition: color 0.2s linear, background-color 0.3s linear;
  color: #8e8e93;
  background-color: transparent;
  width: 100%;
  height: 66px;
  margin: 10px 0 0 0;
  border: 2px dotted #e5e5ea;
  border-radius: 6px;
}
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-ghost {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon {
  padding-left: 24px;
  height: 36px;
  position: relative;
}
.rs-btn-ghost.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon {
  padding-left: 22px;
}
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
  padding-left: 44px;
}
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
  padding-left: 12px;
  padding-right: 44px;
}
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
  left: auto;
  right: 0;
}
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 36px;
  height: 36px;
  padding: 8px 0;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-btn-ghost.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon {
  width: 34px;
  height: 34px;
  padding: 7px 0;
}
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon > svg {
  height: 14px;
  vertical-align: middle;
}
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon-circle {
  width: 36px;
  padding: 8px;
}
.rs-uploader-picture .rs-uploader-trigger-btn:hover,
.rs-uploader-picture .rs-uploader-trigger-btn:focus {
  color: #575757;
  text-decoration: none;
}
.rs-uploader-picture .rs-uploader-trigger-btn:active,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active {
  outline: 0;
  background-image: none;
}
@media not all and (min-resolution: 0.001dpcm) {
  .rs-uploader-picture .rs-uploader-trigger-btn {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-disable-line property-no-vendor-prefix */
  }
}
.rs-uploader-picture .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple {
  position: absolute;
  display: block;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0s;
  transition: 0s;
}
.rs-uploader-picture .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple-rippling {
  -webkit-transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 0;
}
.rs-uploader-picture .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple-pond {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:hover,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:focus,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:active,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-uploader-picture .rs-uploader-trigger-btn:focus,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-focus,
.rs-uploader-picture .rs-uploader-trigger-btn:hover {
  color: #fff;
  background: #f7f7fa;
}
.rs-uploader-picture .rs-uploader-trigger-btn:active,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn {
  color: #fff;
  background-color: #b2b1f0;
  background-image: none;
}
.rs-uploader-picture .rs-uploader-trigger-btn:active:hover,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn:hover,
.rs-uploader-picture .rs-uploader-trigger-btn:active:focus,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn:focus,
.rs-uploader-picture .rs-uploader-trigger-btn:active.focus,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.focus {
  color: #fff;
  background-color: #b2b1f0;
}
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled:hover,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled:focus,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled.focus,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled:active,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:hover,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:focus,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:active,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled:hover,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled:focus,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled:active {
  opacity: 1;
}
.rs-uploader-picture .rs-uploader-trigger-btn:focus,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-focus,
.rs-uploader-picture .rs-uploader-trigger-btn:hover {
  color: #575757;
}
.rs-uploader-picture .rs-uploader-trigger-btn:active,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn {
  color: #272c36;
  background-color: #e5e5ea;
  background-image: none;
}
.rs-uploader-picture .rs-uploader-trigger-btn:active:hover,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn:hover,
.rs-uploader-picture .rs-uploader-trigger-btn:active:focus,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn:focus,
.rs-uploader-picture .rs-uploader-trigger-btn:active.focus,
.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.focus {
  color: #272c36;
  background-color: #e5e5ea;
}

.rs-uploader-picture .rs-uploader-trigger.rs-uploader-trigger-disabled .rs-uploader-trigger-btn {
  color: #8e8e93 !important;
  background-color: transparent !important;
}
.rs-uploader-picture .rs-uploader-trigger.rs-uploader-trigger-disabled .rs-uploader-trigger-btn::after {
  display: none;
}
.rs-uploader-picture .rs-uploader-trigger,
.rs-uploader-picture .rs-uploader-file-item {
  float: left;
}
.rs-uploader-picture .rs-uploader-file-items {
  display: inline;
}
.rs-uploader-picture .rs-uploader-file-item {
  width: 66px;
  height: 66px;
  overflow: hidden;
  background-color: #d9d9d9;
  border-radius: 6px;
  position: relative;
  margin-top: 10px;
  margin-right: 10px;
}
.rs-uploader-picture .rs-uploader-file-item-preview {
  position: relative;
  height: 66px;
  vertical-align: middle;
  text-align: center;
  z-index: 1;
}
.rs-uploader-picture .rs-uploader-file-item-preview > img {
  width: 100%;
  height: auto;
}
@supports ((-o-object-fit: cover) or (object-fit: cover)) {
  .rs-uploader-picture .rs-uploader-file-item-preview > img {
    height: 100%;
    // -o-object-fit: cover;
    // object-fit: cover;
    -o-object-fit: contain;
    object-fit: contain;
  }
}
.rs-uploader-picture .rs-uploader-file-item-status {
  position: absolute;
  top: 0;
  left: 0;
  width: 66px;
  height: 66px;
  text-align: center;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: default;
  color: #f44336;
  padding-top: 14px;
}
.rs-uploader-picture .rs-uploader-file-item-status > span {
  white-space: nowrap;
}
.rs-uploader-picture .rs-uploader-file-item-status > a:hover {
  text-decoration: none;
}
.rs-uploader-picture .rs-uploader-file-item-icon-wrapper {
  display: none;
}
.rs-uploader-picture .rs-uploader-file-item-btn-remove {
  color: #8e8e93;
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 3px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity;
  z-index: 4;
}
.rs-uploader-picture .rs-uploader-file-item-btn-remove:hover,
.rs-uploader-picture .rs-uploader-file-item-btn-remove:focus {
  text-decoration: none;
}
.rs-uploader-picture .rs-uploader-file-item-btn-remove::before {
  font-family: 'rsuite-icon-font';
  font-style: normal;
  line-height: 1;
  content: '\f00d';
}
.rs-uploader-picture .rs-uploader-file-item-btn-remove [aria-hidden] {
  display: none;
}
.rs-uploader-picture .rs-uploader-file-item:hover .rs-uploader-file-item-btn-remove {
  opacity: 1;
  visibility: visible;
}
.rs-uploader-picture .rs-uploader-file-item-icon-loading {
  display: block;
  width: 66px;
  height: 66px;
  line-height: 66px;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  text-align: center;
}
.rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
}
.rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
.rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  width: 18px;
  height: 18px;
}
.rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
.rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  border-radius: 50%;
}
.rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before {
  border: 3px solid rgba(247, 247, 250, 0.8);
}
.rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  border-width: 3px;
  border-style: solid;
  border-color: #a6a6a6 transparent transparent;
  -webkit-animation: loaderSpin 0.6s infinite linear;
  animation: loaderSpin 0.6s infinite linear;
}
.rs-uploader-picture .rs-uploader-file-item-icon-reupload {
  margin-left: 0;
  display: block;
}
.rs-uploader-text .rs-uploader-trigger-btn,
.rs-uploader-picture-text .rs-uploader-trigger-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* stylelint-disable */
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 6px;
  /* stylelint-enable */
  color: #575757;
  background: #f7f7fa;
}
.rs-uploader-text .rs-uploader-trigger-btn.rs-btn-ghost,
.rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-ghost {
  padding-top: 7px;
  padding-bottom: 7px;
}
.rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon,
.rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon {
  padding-left: 24px;
  height: 36px;
  position: relative;
}
.rs-btn-ghost.rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon,
.rs-btn-ghost.rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon {
  padding-left: 22px;
}
.rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
.rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
  padding-left: 44px;
}
.rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right,
.rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
  padding-left: 12px;
  padding-right: 44px;
}
.rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon,
.rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
  left: auto;
  right: 0;
}
.rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon,
.rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 36px;
  height: 36px;
  padding: 8px 0;
  font-size: 14px;
  line-height: 1.42857143;
}
.rs-btn-ghost.rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon,
.rs-btn-ghost.rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon {
  width: 34px;
  height: 34px;
  padding: 7px 0;
}
.rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon > svg,
.rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon > svg {
  height: 14px;
  vertical-align: middle;
}
.rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon-circle,
.rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon-circle {
  width: 36px;
  padding: 8px;
}
.rs-uploader-text .rs-uploader-trigger-btn:hover,
.rs-uploader-picture-text .rs-uploader-trigger-btn:hover,
.rs-uploader-text .rs-uploader-trigger-btn:focus,
.rs-uploader-picture-text .rs-uploader-trigger-btn:focus {
  color: #575757;
  text-decoration: none;
}
.rs-uploader-text .rs-uploader-trigger-btn:active,
.rs-uploader-picture-text .rs-uploader-trigger-btn:active,
.rs-uploader-text .rs-uploader-trigger-btn.rs-btn-active,
.rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-active {
  outline: 0;
  background-image: none;
}
.rs-uploader-text .rs-uploader-trigger-disabled .rs-uploader-trigger-btn,
.rs-uploader-picture-text .rs-uploader-trigger-disabled .rs-uploader-trigger-btn {
  cursor: not-allowed;
}
.rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn,
.rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn {
  overflow: hidden;
  position: relative;
}
@media not all and (min-resolution: 0.001dpcm) {
  .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn,
  .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    /* stylelint-disable-line property-no-vendor-prefix */
  }
}
.rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple,
.rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple {
  position: absolute;
  display: block;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0s;
  transition: 0s;
}
.rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple-rippling,
.rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple-rippling {
  -webkit-transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 0;
}
.rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple-pond,
.rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple-pond {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
.rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:focus,
.rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:focus,
.rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.focus,
.rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.focus {
  color: #575757;
  background-color: #e5e5ea;
}
.rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:hover,
.rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:hover {
  color: #575757;
  background-color: #e5e5ea;
}
.rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:active,
.rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:active,
.rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.rs-btn-active,
.rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.rs-btn-active {
  color: #575757;
  background-color: #d9d9d9;
  background-image: none;
}
.rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:active:hover,
.rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:active:hover,
.rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.rs-btn-active:hover,
.rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.rs-btn-active:hover,
.rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:active:focus,
.rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:active:focus,
.rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.rs-btn-active:focus,
.rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.rs-btn-active:focus,
.rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:active.focus,
.rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:active.focus,
.rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.rs-btn-active.focus,
.rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.rs-btn-active.focus {
  color: #575757;
  background-color: #d9d9d9;
}
.rs-uploader-picture-text .rs-uploader-file-item {
  position: relative;
  height: 50px;
  padding-left: 50px;
  margin-top: 10px;
  padding-right: 30px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  overflow: hidden;
  -webkit-transition: 0.3s linear background-color;
  transition: 0.3s linear background-color;
}
.rs-uploader-picture-text .rs-uploader-file-item:hover {
  background-color: #f7f7fa;
}
.rs-uploader-picture-text .rs-uploader-file-item-icon-wrapper {
  display: none;
}
.rs-uploader-picture-text .rs-uploader-file-item-preview {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  vertical-align: middle;
  text-align: center;
  background-color: #d9d9d9;
  overflow: hidden;
  border-radius: 6px 0 0 6px;
}
.rs-uploader-picture-text .rs-uploader-file-item-preview > img {
  width: 100%;
  height: auto;
}
@supports ((-o-object-fit: cover) or (object-fit: cover)) {
  .rs-uploader-picture-text .rs-uploader-file-item-preview > img {
    height: 100%;
    //-o-object-fit: cover;
    //object-fit: cover;
    -o-object-fit: contain;
    object-fit: contain;
  }
}
.rs-uploader-picture-text .rs-uploader-file-item-content {
  padding: 5px 12px;
}
.rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-title,
.rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-size {
  display: block;
}
.rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  color: #575757;
}
.rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-title:hover,
.rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-title:focus {
  text-decoration: none;
  cursor: default;
}
.rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-size {
  color: #8e8e93;
}
.rs-uploader-picture-text .rs-uploader-file-item-btn-remove {
  position: absolute;
  top: 0;
  right: 12px;
  color: #8e8e93;
  cursor: pointer;
  height: 50px;
  line-height: 50px;
}
.rs-uploader-picture-text .rs-uploader-file-item-btn-remove:hover,
.rs-uploader-picture-text .rs-uploader-file-item-btn-remove:focus {
  text-decoration: none;
}
.rs-uploader-picture-text .rs-uploader-file-item-btn-remove::before {
  font-family: 'rsuite-icon-font';
  font-style: normal;
  line-height: 1;
  content: '\f00d';
}
.rs-uploader-picture-text .rs-uploader-file-item-btn-remove [aria-hidden] {
  display: none;
}
.rs-uploader-picture-text .rs-uploader-file-item-progress {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding-left: 50px;
}
.rs-uploader-picture-text .rs-uploader-file-item-progress-bar {
  height: 2px;
  background-color: $theme-color;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
.rs-uploader-picture-text .rs-uploader-file-item-icon-loading {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 50px;
  height: 50px;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  line-height: 50px;
}
.rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
}
.rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
.rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  width: 18px;
  height: 18px;
}
.rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
.rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  border-radius: 50%;
}
.rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before {
  border: 3px solid rgba(247, 247, 250, 0.8);
}
.rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
  border-width: 3px;
  border-style: solid;
  border-color: #a6a6a6 transparent transparent;
  -webkit-animation: loaderSpin 0.6s infinite linear;
  animation: loaderSpin 0.6s infinite linear;
}
.rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-size {
  display: none;
}
.rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-title,
.rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-status {
  display: block;
  color: $theme-color;
}
.rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-title a,
.rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-status a,
.rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-title a:hover,
.rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-status a:hover {
  text-decoration: none;
}
.rs-uploader-draggable .rs-uploader-trigger-customize .rs-uploader-trigger-btn {
  display: block;
  background: #fff;
  border: 1px dashed #e5e5ea;
}
.rs-uploader-draggable .rs-uploader-trigger.rs-uploader-trigger-customize:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:hover,
.rs-uploader-draggable .rs-uploader-trigger-drag-over .rs-uploader-trigger-btn.rs-uploader-trigger-customize {
  border-color: $theme-color;
}
.rs-uploader-draggable .rs-uploader-trigger-disabled {
  cursor: not-allowed;
}
.rs-uploader-draggable .rs-uploader-trigger-disabled .rs-uploader-trigger-btn {
  pointer-events: none;
}
