.dashboard-view {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .content-main {
    flex: 1;
    display: flex;

    .main-container {
      flex: 1;
      transition: all 0.3s ease-in-out;

      .content {
        padding: 2.5rem;
        position: relative;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .dashboard-view {
    .content-main {
      .main-container {
        margin-left: 0;

        &.collapsed {
          margin-left: $menu-width;
        }
      }
    }
  }
}
