.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.hidden {
  display: none !important;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.fade.in {
  opacity: 1;
}

@font-face {
  font-family: 'rsuite-icon-font';
  src: url('https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.ttf') format('truetype'), url('https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.rs-picker-subtle .picker-subtle-toggle {
  position: relative;
  z-index: 5;
  padding-right: 3.2rem;
  display: inline-block;
  outline: none;
  cursor: pointer;
}

.rs-picker-subtle .picker-subtle-toggle-custom-title {
  padding: 0 !important;
}

.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle {
  cursor: not-allowed;
}

.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active {
  background: none;
}

.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover::after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus::after,
.rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active::after {
  display: none;
}

.rs-calendar {
  background-color: #fff;
  padding-top: 1.2rem;
  position: relative;
  min-height: 2.6rem;
  overflow: hidden;
}
.rs-calendar-bordered .rs-calendar-table {
  border: 0.1rem solid #f2f2f5;
  border-radius: 0.5rem;
}
.rs-calendar-bordered .rs-calendar-table-row:not(:last-child) .rs-calendar-table-cell,
.rs-calendar-bordered .rs-calendar-table-header-row .rs-calendar-table-cell {
  border-bottom: 0.1rem solid #f2f2f5;
}
.rs-calendar-bordered .rs-calendar-month-dropdown {
  border: 0.1rem solid #e5e5ea;
  border-radius: 0.5rem;
}
.rs-calendar-panel .rs-calendar-header {
  width: 100%;
  padding-bottom: 1.5rem;
  border-bottom: 0.1rem solid #e5e5ea;
}
.rs-calendar-panel .rs-calendar-header::before,
.rs-calendar-panel .rs-calendar-header::after {
  content: ' ';
  display: table;
}
.rs-calendar-panel .rs-calendar-header::after {
  clear: both;
}
.rs-calendar-panel .rs-calendar-header::before,
.rs-calendar-panel .rs-calendar-header::after {
  content: ' ';
  display: table;
}
.rs-calendar-panel .rs-calendar-header::after {
  clear: both;
}
.rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
  float: right;
}
.rs-calendar-panel .rs-calendar-header-forward,
.rs-calendar-panel .rs-calendar-header-title,
.rs-calendar-panel .rs-calendar-header-backward {
  /* stylelint-disable */
  padding: 0.8rem 1.2rem;
  font-size: 1.4rem;
  line-height: 1.42857143;
  border-radius: 0.5rem;
  /* stylelint-enable */
}
.rs-calendar-panel .rs-calendar-header-forward.rs-btn-ghost,
.rs-calendar-panel .rs-calendar-header-title.rs-btn-ghost,
.rs-calendar-panel .rs-calendar-header-backward.rs-btn-ghost {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon,
.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon,
.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon {
  padding-left: 2.4rem;
  height: 3.6rem;
  position: relative;
}
.rs-btn-ghost.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon,
.rs-btn-ghost.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon,
.rs-btn-ghost.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon {
  padding-left: 2.2rem;
}
.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
  padding-left: 4.4rem;
}
.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right,
.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right,
.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
  padding-left: 1.2rem;
  padding-right: 4.4rem;
}
.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon,
.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon,
.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
  left: auto;
  right: 0;
}
.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon > .rs-icon,
.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon > .rs-icon,
.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 3.6rem;
  height: 3.6rem;
  padding: 0.8rem 0;
  font-size: 1.4rem;
  line-height: 1.42857143;
}
.rs-btn-ghost.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon > .rs-icon,
.rs-btn-ghost.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon > .rs-icon,
.rs-btn-ghost.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon > .rs-icon {
  width: 3.4rem;
  height: 3.4rem;
  padding: 0.7rem 0;
}
.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon > .rs-icon > svg,
.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon > .rs-icon > svg,
.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon > .rs-icon > svg {
  height: 1.4rem;
  vertical-align: middle;
}
.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon-circle,
.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon-circle,
.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon-circle {
  width: 3.6rem;
  padding: 0.8rem;
}
.rs-calendar-panel .rs-calendar-header-forward,
.rs-calendar-panel .rs-calendar-header-backward {
  width: 30.1rem;
  float: none !important;
}
.rs-calendar-panel.rs-calendar-show-month-dropdown .rs-calendar-header-forward,
.rs-calendar-panel.rs-calendar-show-month-dropdown .rs-calendar-header-backward {
  display: inline-block;
  visibility: hidden;
  pointer-events: none;
}
.rs-calendar-panel .rs-calendar-header-month-toolbar {
  width: auto !important;
}
.rs-calendar-panel.rs-calendar-show-month-dropdown .rs-calendar-header-month-toolbar {
  padding-left: 0;
  padding-right: 0;
}
.rs-calendar-panel .rs-calendar-show-month-dropdown {
  padding-left: 0;
  padding-right: 0;
}
.rs-calendar-panel .rs-calendar-table-header-row .rs-calendar-table-cell-content {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
  color: #fff;
  width: 2rem;
  height: 2rem;
  background-color: $theme-second-color;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
.rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border-color: transparent;
}
.rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: transparent;
  color: inherit;
  border: 0.1rem solid $theme-second-color;
  padding: 0.4rem;
}
.rs-calendar-panel .rs-calendar-table-cell-day {
  margin-top: 0.3rem;
  display: block;
  text-align: center;
}
.rs-calendar-panel .rs-calendar-month-dropdown {
  margin-left: 1.2rem;
  margin-right: 1.2rem;
  top: 5.4rem;
  width: calc(100% - 2.4rem);
}
.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-month-dropdown-scroll {
  height: 64.4rem;
}
.rs-calendar-panel.rs-calendar-compact .rs-calendar-month-dropdown-scroll {
  height: 34.4rem;
}
.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
  width: 100%;
  height: 10rem;
  overflow: hidden;
}
.rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-cell {
  vertical-align: top;
}
.rs-calendar-panel .rs-calendar-table-cell {
  padding: 0 0.1rem;
  line-height: 0;
}
.rs-calendar-panel .rs-calendar-table-cell-content {
  width: 100%;
  border-radius: 0;
}
.rs-calendar-panel .rs-calendar-table-row:last-child :first-child .rs-calendar-table-cell-content {
  border-bottom-left-radius: 0.5rem;
}
.rs-calendar-panel .rs-calendar-table-row:last-child :last-child .rs-calendar-table-cell-content {
  border-bottom-right-radius: 0.5rem;
}
.rs-calendar-panel.rs-calendar-compact .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
  height: 5rem;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-month-toolbar {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-backward,
.rs-calendar-show-month-dropdown .rs-calendar-header-forward {
  display: none;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-title-date {
  color: $theme-second-color;
  background: transparent;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-title-date.rs-calendar-header-error {
  color: #f44336;
}
.rs-calendar-show-month-dropdown .rs-calendar-header-title-date.rs-calendar-header-error:focus,
.rs-calendar-show-month-dropdown .rs-calendar-header-title-date.rs-calendar-header-error:hover:active {
  color: #fff !important;
}
.rs-calendar-show-time-dropdown .rs-calendar-header-title-time {
  color: darken($theme-second-color, 20%);
  background: transparent;
}
.rs-calendar-show-time-dropdown .rs-calendar-header-title-time.rs-calendar-header-error {
  color: #f44336;
}
.rs-calendar-show-time-dropdown .rs-calendar-header-title-time.rs-calendar-header-error:hover:active {
  color: #fff;
}
.rs-calendar-table-cell-selected,
.rs-calendar-table-cell-in-range {
  position: relative;
}
.rs-calendar-table-cell-selected::before,
.rs-calendar-table-cell-in-range::before {
  content: '';
  display: block;
  width: 100%;
  margin-top: 0.3rem;
  height: 2.4rem;
  position: absolute;
  z-index: 0;
  top: 0;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content {
  z-index: 1;
  position: relative;
}
.rs-calendar-table-cell-in-range::before {
  background-color: #e2e5e8;
}
.rs-calendar-month-dropdown-scroll div:focus {
  outline: none;
}
.rs-calendar-header {
  width: 28rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  border-bottom: 0.1rem solid #e5e5ea;
}
.rs-calendar-header::before,
.rs-calendar-header::after {
  content: ' ';
  display: table;
}
.rs-calendar-header::after {
  clear: both;
}
.rs-calendar-header::before,
.rs-calendar-header::after {
  content: ' ';
  display: table;
}
.rs-calendar-header::after {
  clear: both;
}
.rs-calendar-header-month-toolbar,
.rs-calendar-header-time-toolbar {
  display: inline-block;
}
.rs-calendar-header-month-toolbar {
  float: left;
}
.rs-calendar-header-time-toolbar {
  float: right;
}
.rs-calendar-header-title,
.rs-calendar-header-meridian,
.rs-calendar-header-backward,
.rs-calendar-header-forward {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: color 0.2s linear, background-color 0.3s linear;
  transition: color 0.2s linear, background-color 0.3s linear;
  // color: #8e8e93;
  font-weight: 500;
  background-color: transparent;
  border-radius: 0.5rem;
  padding: 0.2rem 0.2rem;
  font-size: 1.4rem;
  line-height: 1.42857143;
}
.rs-calendar-header-title.rs-btn-ghost,
.rs-calendar-header-meridian.rs-btn-ghost,
.rs-calendar-header-backward.rs-btn-ghost,
.rs-calendar-header-forward.rs-btn-ghost {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
.rs-calendar-header-title.rs-btn-icon,
.rs-calendar-header-meridian.rs-btn-icon,
.rs-calendar-header-backward.rs-btn-icon,
.rs-calendar-header-forward.rs-btn-icon {
  padding-left: 2.4rem;
  height: 3.6rem;
  position: relative;
}
.rs-btn-ghost.rs-calendar-header-title.rs-btn-icon,
.rs-btn-ghost.rs-calendar-header-meridian.rs-btn-icon,
.rs-btn-ghost.rs-calendar-header-backward.rs-btn-icon,
.rs-btn-ghost.rs-calendar-header-forward.rs-btn-icon {
  padding-left: 2.2rem;
}
.rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
.rs-calendar-header-meridian.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
.rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
.rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
  padding-left: 4.4rem;
}
.rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right,
.rs-calendar-header-meridian.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right,
.rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right,
.rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
  padding-left: 1.2rem;
  padding-right: 4.4rem;
}
.rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon,
.rs-calendar-header-meridian.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon,
.rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon,
.rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
  left: auto;
  right: 0;
}
.rs-calendar-header-title.rs-btn-icon > .rs-icon,
.rs-calendar-header-meridian.rs-btn-icon > .rs-icon,
.rs-calendar-header-backward.rs-btn-icon > .rs-icon,
.rs-calendar-header-forward.rs-btn-icon > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 3.6rem;
  height: 3.6rem;
  padding: 0.8rem 0;
  font-size: 1.4rem;
  line-height: 1.42857143;
}
.rs-btn-ghost.rs-calendar-header-title.rs-btn-icon > .rs-icon,
.rs-btn-ghost.rs-calendar-header-meridian.rs-btn-icon > .rs-icon,
.rs-btn-ghost.rs-calendar-header-backward.rs-btn-icon > .rs-icon,
.rs-btn-ghost.rs-calendar-header-forward.rs-btn-icon > .rs-icon {
  width: 3.4rem;
  height: 3.4rem;
  padding: 0.7rem 0;
}
.rs-calendar-header-title.rs-btn-icon > .rs-icon > svg,
.rs-calendar-header-meridian.rs-btn-icon > .rs-icon > svg,
.rs-calendar-header-backward.rs-btn-icon > .rs-icon > svg,
.rs-calendar-header-forward.rs-btn-icon > .rs-icon > svg {
  height: 1.4rem;
  vertical-align: middle;
}
.rs-calendar-header-title.rs-btn-icon-circle,
.rs-calendar-header-meridian.rs-btn-icon-circle,
.rs-calendar-header-backward.rs-btn-icon-circle,
.rs-calendar-header-forward.rs-btn-icon-circle {
  width: 3.6rem;
  padding: 0.8rem;
}
.rs-calendar-header-title:hover,
.rs-calendar-header-meridian:hover,
.rs-calendar-header-backward:hover,
.rs-calendar-header-forward:hover,
.rs-calendar-header-title:focus,
.rs-calendar-header-meridian:focus,
.rs-calendar-header-backward:focus,
.rs-calendar-header-forward:focus {
  color: #575757;
  text-decoration: none;
}
.rs-calendar-header-title:active,
.rs-calendar-header-meridian:active,
.rs-calendar-header-backward:active,
.rs-calendar-header-forward:active,
.rs-calendar-header-title.rs-btn-active,
.rs-calendar-header-meridian.rs-btn-active,
.rs-calendar-header-backward.rs-btn-active,
.rs-calendar-header-forward.rs-btn-active {
  outline: 0;
  background-image: none;
}
.rs-calendar-header-title.rs-btn-loading:hover,
.rs-calendar-header-meridian.rs-btn-loading:hover,
.rs-calendar-header-backward.rs-btn-loading:hover,
.rs-calendar-header-forward.rs-btn-loading:hover,
.rs-calendar-header-title.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-meridian.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-backward.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-forward.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-title.rs-btn-loading:focus,
.rs-calendar-header-meridian.rs-btn-loading:focus,
.rs-calendar-header-backward.rs-btn-loading:focus,
.rs-calendar-header-forward.rs-btn-loading:focus,
.rs-calendar-header-title.rs-btn-loading:active,
.rs-calendar-header-meridian.rs-btn-loading:active,
.rs-calendar-header-backward.rs-btn-loading:active,
.rs-calendar-header-forward.rs-btn-loading:active,
.rs-calendar-header-title.rs-btn-loading:hover:active,
.rs-calendar-header-meridian.rs-btn-loading:hover:active,
.rs-calendar-header-backward.rs-btn-loading:hover:active,
.rs-calendar-header-forward.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-calendar-header-title:focus,
.rs-calendar-header-meridian:focus,
.rs-calendar-header-backward:focus,
.rs-calendar-header-forward:focus,
.rs-calendar-header-title.rs-btn-focus,
.rs-calendar-header-meridian.rs-btn-focus,
.rs-calendar-header-backward.rs-btn-focus,
.rs-calendar-header-forward.rs-btn-focus,
.rs-calendar-header-title:hover,
.rs-calendar-header-meridian:hover,
.rs-calendar-header-backward:hover,
.rs-calendar-header-forward:hover {
  color: #fff;
  background: #f7f7fa;
}
.rs-calendar-header-title:active,
.rs-calendar-header-meridian:active,
.rs-calendar-header-backward:active,
.rs-calendar-header-forward:active,
.rs-calendar-header-title.rs-btn-active,
.rs-calendar-header-meridian.rs-btn-active,
.rs-calendar-header-backward.rs-btn-active,
.rs-calendar-header-forward.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward {
  color: #fff;
  background-color: #b2b1f0;
  background-image: none;
}
.rs-calendar-header-title:active:hover,
.rs-calendar-header-meridian:active:hover,
.rs-calendar-header-backward:active:hover,
.rs-calendar-header-forward:active:hover,
.rs-calendar-header-title.rs-btn-active:hover,
.rs-calendar-header-meridian.rs-btn-active:hover,
.rs-calendar-header-backward.rs-btn-active:hover,
.rs-calendar-header-forward.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward:hover,
.rs-calendar-header-title:active:focus,
.rs-calendar-header-meridian:active:focus,
.rs-calendar-header-backward:active:focus,
.rs-calendar-header-forward:active:focus,
.rs-calendar-header-title.rs-btn-active:focus,
.rs-calendar-header-meridian.rs-btn-active:focus,
.rs-calendar-header-backward.rs-btn-active:focus,
.rs-calendar-header-forward.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward:focus,
.rs-calendar-header-title:active.focus,
.rs-calendar-header-meridian:active.focus,
.rs-calendar-header-backward:active.focus,
.rs-calendar-header-forward:active.focus,
.rs-calendar-header-title.rs-btn-active.focus,
.rs-calendar-header-meridian.rs-btn-active.focus,
.rs-calendar-header-backward.rs-btn-active.focus,
.rs-calendar-header-forward.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.focus {
  color: #fff;
  background-color: #b2b1f0;
}
.rs-calendar-header-title.rs-btn-disabled,
.rs-calendar-header-meridian.rs-btn-disabled,
.rs-calendar-header-backward.rs-btn-disabled,
.rs-calendar-header-forward.rs-btn-disabled,
.rs-calendar-header-title.rs-btn-disabled:hover,
.rs-calendar-header-meridian.rs-btn-disabled:hover,
.rs-calendar-header-backward.rs-btn-disabled:hover,
.rs-calendar-header-forward.rs-btn-disabled:hover,
.rs-calendar-header-title.rs-btn-disabled:focus,
.rs-calendar-header-meridian.rs-btn-disabled:focus,
.rs-calendar-header-backward.rs-btn-disabled:focus,
.rs-calendar-header-forward.rs-btn-disabled:focus,
.rs-calendar-header-title.rs-btn-disabled.focus,
.rs-calendar-header-meridian.rs-btn-disabled.focus,
.rs-calendar-header-backward.rs-btn-disabled.focus,
.rs-calendar-header-forward.rs-btn-disabled.focus,
.rs-calendar-header-title.rs-btn-disabled:active,
.rs-calendar-header-meridian.rs-btn-disabled:active,
.rs-calendar-header-backward.rs-btn-disabled:active,
.rs-calendar-header-forward.rs-btn-disabled:active,
.rs-calendar-header-title.rs-btn-disabled:hover:active,
.rs-calendar-header-meridian.rs-btn-disabled:hover:active,
.rs-calendar-header-backward.rs-btn-disabled:hover:active,
.rs-calendar-header-forward.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-calendar-header-title.rs-btn-loading:hover,
.rs-calendar-header-meridian.rs-btn-loading:hover,
.rs-calendar-header-backward.rs-btn-loading:hover,
.rs-calendar-header-forward.rs-btn-loading:hover,
.rs-calendar-header-title.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-meridian.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-backward.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-forward.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-title.rs-btn-loading:focus,
.rs-calendar-header-meridian.rs-btn-loading:focus,
.rs-calendar-header-backward.rs-btn-loading:focus,
.rs-calendar-header-forward.rs-btn-loading:focus,
.rs-calendar-header-title.rs-btn-loading:active,
.rs-calendar-header-meridian.rs-btn-loading:active,
.rs-calendar-header-backward.rs-btn-loading:active,
.rs-calendar-header-forward.rs-btn-loading:active,
.rs-calendar-header-title.rs-btn-loading:hover:active,
.rs-calendar-header-meridian.rs-btn-loading:hover:active,
.rs-calendar-header-backward.rs-btn-loading:hover:active,
.rs-calendar-header-forward.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-calendar-header-title.rs-btn-disabled,
.rs-calendar-header-meridian.rs-btn-disabled,
.rs-calendar-header-backward.rs-btn-disabled,
.rs-calendar-header-forward.rs-btn-disabled,
.rs-calendar-header-title.rs-btn-disabled:hover,
.rs-calendar-header-meridian.rs-btn-disabled:hover,
.rs-calendar-header-backward.rs-btn-disabled:hover,
.rs-calendar-header-forward.rs-btn-disabled:hover,
.rs-calendar-header-title.rs-btn-disabled:focus,
.rs-calendar-header-meridian.rs-btn-disabled:focus,
.rs-calendar-header-backward.rs-btn-disabled:focus,
.rs-calendar-header-forward.rs-btn-disabled:focus,
.rs-calendar-header-title.rs-btn-disabled:active,
.rs-calendar-header-meridian.rs-btn-disabled:active,
.rs-calendar-header-backward.rs-btn-disabled:active,
.rs-calendar-header-forward.rs-btn-disabled:active {
  opacity: 1;
}
.rs-calendar-header-title:focus,
.rs-calendar-header-meridian:focus,
.rs-calendar-header-backward:focus,
.rs-calendar-header-forward:focus,
.rs-calendar-header-title.rs-btn-focus,
.rs-calendar-header-meridian.rs-btn-focus,
.rs-calendar-header-backward.rs-btn-focus,
.rs-calendar-header-forward.rs-btn-focus,
.rs-calendar-header-title:hover,
.rs-calendar-header-meridian:hover,
.rs-calendar-header-backward:hover,
.rs-calendar-header-forward:hover {
  color: #575757;
}
.rs-calendar-header-title:active,
.rs-calendar-header-meridian:active,
.rs-calendar-header-backward:active,
.rs-calendar-header-forward:active,
.rs-calendar-header-title.rs-btn-active,
.rs-calendar-header-meridian.rs-btn-active,
.rs-calendar-header-backward.rs-btn-active,
.rs-calendar-header-forward.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward {
  color: #272c36;
  background-color: #e5e5ea;
  background-image: none;
}
.rs-calendar-header-title:active:hover,
.rs-calendar-header-meridian:active:hover,
.rs-calendar-header-backward:active:hover,
.rs-calendar-header-forward:active:hover,
.rs-calendar-header-title.rs-btn-active:hover,
.rs-calendar-header-meridian.rs-btn-active:hover,
.rs-calendar-header-backward.rs-btn-active:hover,
.rs-calendar-header-forward.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward:hover,
.rs-calendar-header-title:active:focus,
.rs-calendar-header-meridian:active:focus,
.rs-calendar-header-backward:active:focus,
.rs-calendar-header-forward:active:focus,
.rs-calendar-header-title.rs-btn-active:focus,
.rs-calendar-header-meridian.rs-btn-active:focus,
.rs-calendar-header-backward.rs-btn-active:focus,
.rs-calendar-header-forward.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward:focus,
.rs-calendar-header-title:active.focus,
.rs-calendar-header-meridian:active.focus,
.rs-calendar-header-backward:active.focus,
.rs-calendar-header-forward:active.focus,
.rs-calendar-header-title.rs-btn-active.focus,
.rs-calendar-header-meridian.rs-btn-active.focus,
.rs-calendar-header-backward.rs-btn-active.focus,
.rs-calendar-header-forward.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-title.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.focus {
  color: #272c36;
  background-color: #e5e5ea;
}

.rs-calendar-header-title {
  margin: 0 0;
}
.rs-calendar-header-meridian {
  line-height: 1.2rem;
  border: 0.1rem solid #e5e5ea;
  font-size: 1.2rem;
  padding: 0.2rem 1.2rem 0.2rem 0.2rem;
  margin-left: 0.4rem;
}
.rs-calendar-header-meridian::after {
  -webkit-transform: scale(0.7, 0.9);
  transform: scale(0.7, 0.9);
  position: absolute;
  font-family: 'rsuite-icon-font';
  content: '\f0dc';
}
.rs-calendar-header-error {
  background-color: transparent;
  color: #f44336;
}
.rs-calendar-header-error.rs-btn-loading:hover,
.rs-calendar-header-error.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-calendar-header-error.rs-btn-loading:focus,
.rs-calendar-header-error.rs-btn-loading:active,
.rs-calendar-header-error.rs-btn-loading:hover:active {
  color: #8e8e93;
  background-color: transparent;
}
.rs-calendar-header-error:focus,
.rs-calendar-header-error.rs-btn-focus,
.rs-calendar-header-error:hover {
  color: #fff;
  background: #f44336;
}
.rs-calendar-header-error:active,
.rs-calendar-header-error.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-error {
  color: #fff;
  background-color: #eb3626;
  background-image: none;
}
.rs-calendar-header-error:active:hover,
.rs-calendar-header-error.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-error:hover,
.rs-calendar-header-error:active:focus,
.rs-calendar-header-error.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-error:focus,
.rs-calendar-header-error:active.focus,
.rs-calendar-header-error.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-calendar-header-error.focus {
  color: #fff;
  background-color: #eb3626;
}
.rs-calendar-header-error.rs-btn-disabled,
.rs-calendar-header-error.rs-btn-disabled:hover,
.rs-calendar-header-error.rs-btn-disabled:focus,
.rs-calendar-header-error.rs-btn-disabled.focus,
.rs-calendar-header-error.rs-btn-disabled:active,
.rs-calendar-header-error.rs-btn-disabled:hover:active {
  color: #c5c6c7;
  background: none;
}
.rs-calendar-header-btn-disabled,
.rs-calendar-header-btn-disabled:hover,
.rs-calendar-header-btn-disabled:hover:focus {
  cursor: not-allowed;
  opacity: 0.3;
  background: none;
}
.rs-calendar-header-btn-disabled::after {
  display: none !important;
}
.rs-calendar-header-backward,
.rs-calendar-header-forward {
  font-size: 0;
  width: 2.4rem;
}
.rs-calendar-header-backward::before,
.rs-calendar-header-forward::before {
  font-family: 'rsuite-icon-font';
  font-style: normal;
  font-size: 1.4rem;
}
.rs-calendar-header-backward::before {
  content: '\ea0a';
}
.rs-calendar-header-forward::before {
  content: '\ea0c';
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
  margin: 0 auto;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
  display: block;
  text-align: center;
  width: 100%;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::before,
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
  content: ' ';
  display: table;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
  clear: both;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::before,
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
  content: ' ';
  display: table;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
  clear: both;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-backward {
  float: left;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-forward {
  float: right;
}
.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) {
  margin: 0 auto;
  text-align: center;
}
.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) .rs-calendar-header-time-toolbar {
  float: none;
  display: inline-block;
}
.rs-calendar-header-has-month.rs-calendar-header-has-time {
  margin: 0 auto;
}
/* rtl:begin:ignore */
/* stylelint-disable-next-line */
[dir='rtl'] .rs-calendar-header-backward,
[dir='rtl'] .rs-calendar-header-forward {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
/* rtl:end:ignore */
.rs-calendar-view {
  padding: 0.4rem 1.2rem 1.2rem;
}
.rs-calendar-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.rs-calendar-table-row {
  display: table-row;
}
.rs-calendar-table-row:nth-child(2) .rs-calendar-table-cell-week-number {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.rs-calendar-table-row:last-child .rs-calendar-table-cell-week-number {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.rs-calendar-table-cell {
  display: table-cell;
  width: 1%;
  padding: 0.1rem;
  text-align: center;
  vertical-align: middle;
}
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover {
  color: #c5c6c7;
}
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover {
  background: none;
  text-decoration: line-through;
  cursor: not-allowed;
}
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
  color: #ffffff;
  border-color: $theme-second-color;
  background-color: $theme-second-color;
}
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  opacity: 0.3;
}
.rs-calendar-table-cell-content {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.42857143;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
}
.rs-calendar-table-cell-content:hover {
  background-color: #f2faff;
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border: 0.1rem solid darken($theme-second-color, 20%);
  padding: 0.4rem;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #ffffff;
  border-color: $theme-second-color;
  background-color: $theme-second-color;
}
.rs-calendar-table-header-row .rs-calendar-table-cell-content {
  color: #8e8e93;
  font-size: 1.2rem;
  line-height: 1.66666667;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.rs-calendar-table-header-row .rs-calendar-table-cell-content,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:focus,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover:focus {
  background: none;
  cursor: auto;
}
.rs-calendar-table-cell-week-number {
  display: table-cell;
  width: 1%;
  padding: 0.1rem;
  text-align: center;
  vertical-align: middle;
  color: #8e8e93;
  background: #f7f7fa;
  font-size: 1.2rem;
}
.rs-calendar-month-dropdown {
  display: none;
  position: absolute;
  top: 4rem;
  border-top: 0.1rem solid #e5e5ea;
  margin-top: 0.4rem;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  z-index: 1;
}
.rs-calendar-show-month-dropdown .rs-calendar-month-dropdown {
  display: block;
}
.rs-calendar-show-month-dropdown .rs-calendar-month-dropdown-content {
  -webkit-animation: 0.3s linear slideDown;
  animation: 0.3s linear slideDown;
}
.rs-calendar-month-dropdown-scroll {
  height: 23rem;
}
.rs-calendar-month-dropdown-row {
  position: relative;
  padding-left: 50.2rem;
  padding-right: 1.2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.rs-calendar-month-dropdown-row:not(:last-child) {
  border-bottom: 0.1rem dotted #e5e5ea;
}
.rs-calendar-month-dropdown-year {
  position: absolute;
  top: calc(50% - 0.5em);
  left: 1.2rem;
}
.rs-calendar-month-dropdown-year-active {
  color: darken($theme-second-color, 20% );
}
.rs-calendar-month-dropdown-list {
  display: block;
}
.rs-calendar-month-dropdown-list::before,
.rs-calendar-month-dropdown-list::after {
  content: ' ';
  display: table;
}
.rs-calendar-month-dropdown-list::after {
  clear: both;
}
.rs-calendar-month-dropdown-list::before,
.rs-calendar-month-dropdown-list::after {
  content: ' ';
  display: table;
}
.rs-calendar-month-dropdown-list::after {
  clear: both;
}
.rs-calendar-month-dropdown-cell {
  display: inline-block;
  float: left;
  width: calc((100% - 0.1rem * 12 ) / 6);
  margin: 0.1rem;
  text-align: center;
  vertical-align: middle;
  width: calc((100% - 0.1rem * 12 - 0.1rem) / 6)\9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-calendar-month-dropdown-cell {
    width: calc((100% - 0.1rem * 12 - 0.1rem) / 6);
  }
}
@supports (-ms-ime-align: auto) {
  .rs-calendar-month-dropdown-cell {
    width: calc((100% - 0.1rem * 12 - 0.1rem) / 6);
  }
}
.rs-calendar-month-dropdown-cell-content {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1.42857143;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
}
.rs-calendar-month-dropdown-cell-content:hover {
  background-color: #f2faff;
}
.rs-calendar-month-dropdown-cell:not(.rs-calendar-month-dropdown-cell-active).disabled .rs-calendar-month-dropdown-cell-content {
  color: #c5c6c7;
  background: none;
  text-decoration: line-through;
  cursor: not-allowed;
}
.rs-calendar-month-dropdown-cell-active.disabled .rs-calendar-month-dropdown-cell-content {
  opacity: 0.3;
  cursor: not-allowed;
}
.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
  color: #ffffff;
  border-color: $theme-second-color;
  background-color: $theme-second-color;
}
.rs-calendar-time-dropdown {
  display: none;
  position: absolute;
  top: 4rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  z-index: 1;
}
.rs-calendar-show-time-dropdown .rs-calendar-time-dropdown {
  display: block;
}
.rs-calendar-show-time-dropdown .rs-calendar-time-dropdown-content {
  -webkit-animation: 0.3s linear slideDown;
  animation: 0.3s linear slideDown;
}
.rs-calendar-time-dropdown-row {
  display: table;
  width: 100%;
}
.rs-calendar-time-dropdown-column {
  display: table-cell;
  width: 1%;
}
.rs-calendar-time-dropdown-column > ul,
.rs-calendar-time-dropdown-column > ul > li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rs-calendar-time-dropdown-column > ul {
  height: 23rem;
  overflow-y: auto;
  padding-bottom: 20rem;
}
.rs-calendar-time-dropdown-column-title {
  width: 100%;
  background-color: #f7f7fa;
  color: #8e8e93;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.66666667;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.rs-calendar-time-dropdown-cell {
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 0.5rem;
  display: block;
  color: #575757;
  text-align: center;
  cursor: pointer;
  -webkit-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
}
.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell,
.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell:hover {
  background-color: $theme-second-color;
  color: #fff;
}
.rs-calendar-time-dropdown-cell,
.rs-calendar-time-dropdown-cell:hover,
.rs-calendar-time-dropdown-cell:focus {
  text-decoration: none;
}
.rs-calendar-time-dropdown-cell:hover {
  color: #575757;
  background-color: #f2faff;
}
.rs-calendar-time-dropdown-cell-disabled,
.rs-calendar-time-dropdown-cell-disabled:hover,
.rs-calendar-time-dropdown-cell-disabled:focus {
  color: #c5c6c7;
  background: none;
  cursor: not-allowed;
  text-decoration: line-through;
}
.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell-disabled.rs-calendar-time-dropdown-cell {
  opacity: 0.3;
  cursor: not-allowed;
}

.rs-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}
.rs-container-has-sidebar {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.rs-content {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.rs-control-label {
  margin-bottom: 0.4rem;
}
.rs-picker-date.rs-picker-default .rs-picker-toggle-caret,
.rs-picker-daterange.rs-picker-default .rs-picker-toggle-caret,
.rs-picker-date.rs-picker-default .rs-picker-toggle-clean,
.rs-picker-daterange.rs-picker-default .rs-picker-toggle-clean {
  top: 0.7rem;
}
.rs-picker-toolbar {
  padding: 1.2rem;
  border-top: 0.1rem solid #e5e5ea;
}
.rs-picker-toolbar::before,
.rs-picker-toolbar::after {
  content: ' ';
  display: table;
}
.rs-picker-toolbar::after {
  clear: both;
}
.rs-picker-toolbar::before,
.rs-picker-toolbar::after {
  content: ' ';
  display: table;
}
.rs-picker-toolbar::after {
  clear: both;
}
.rs-picker-toolbar-ranges {
  display: inline-block;
  margin-left: -1rem;
  margin-top: 0.5rem;
}
.rs-picker-toolbar-right {
  float: right;
}
.rs-picker-toolbar-option {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.4rem;
  line-height: 1.42857143;
  color: darken($theme-second-color, 20%);
  font-weight: normal;
  border-radius: 0;
  padding: 0 1rem;
}
.rs-picker-toolbar-option.rs-btn-ghost {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
.rs-picker-toolbar-option.rs-btn-icon {
  padding-left: 2.4rem;
  height: 3.6rem;
  position: relative;
}
.rs-btn-ghost.rs-picker-toolbar-option.rs-btn-icon {
  padding-left: 2.2rem;
}
.rs-picker-toolbar-option.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
  padding-left: 4.4rem;
}
.rs-picker-toolbar-option.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
  padding-left: 1.2rem;
  padding-right: 4.4rem;
}
.rs-picker-toolbar-option.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
  left: auto;
  right: 0;
}
.rs-picker-toolbar-option.rs-btn-icon > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 3.6rem;
  height: 3.6rem;
  padding: 0.8rem 0;
  font-size: 1.4rem;
  line-height: 1.42857143;
}
.rs-btn-ghost.rs-picker-toolbar-option.rs-btn-icon > .rs-icon {
  width: 3.4rem;
  height: 3.4rem;
  padding: 0.7rem 0;
}
.rs-picker-toolbar-option.rs-btn-icon > .rs-icon > svg {
  height: 1.4rem;
  vertical-align: middle;
}
.rs-picker-toolbar-option.rs-btn-icon-circle {
  width: 3.6rem;
  padding: 0.8rem;
}
.rs-picker-toolbar-option:hover,
.rs-picker-toolbar-option:focus {
  color: #575757;
  text-decoration: none;
}
.rs-picker-toolbar-option:active,
.rs-picker-toolbar-option.rs-btn-active {
  outline: 0;
  background-image: none;
}
.rs-picker-toolbar-option.rs-btn-loading:hover,
.rs-picker-toolbar-option.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-toolbar-option.rs-btn-loading:focus,
.rs-picker-toolbar-option.rs-btn-loading:active,
.rs-picker-toolbar-option.rs-btn-loading:hover:active {
  color: darken($theme-second-color, 20%);
  background-color: transparent;
}
.rs-picker-toolbar-option,
.rs-picker-toolbar-option:active,
.rs-picker-toolbar-option.rs-btn-active {
  background-color: transparent;
}
.rs-picker-toolbar-option,
.rs-picker-toolbar-option:hover,
.rs-picker-toolbar-option:focus,
.rs-picker-toolbar-option:active {
  border-color: transparent;
}
.rs-picker-toolbar-option:hover,
.rs-picker-toolbar-option:focus {
  color: darken($theme-second-color, 20%);
  text-decoration: underline;
  background-color: transparent;
}
.rs-picker-toolbar-option:active,
.rs-picker-toolbar-option.rs-btn-active {
  color: darken($theme-second-color, 20%);
}
.rs-picker-toolbar-option:active:hover,
.rs-picker-toolbar-option.rs-btn-active:hover,
.rs-picker-toolbar-option:active:focus,
.rs-picker-toolbar-option.rs-btn-active:focus,
.rs-picker-toolbar-option:active.focus,
.rs-picker-toolbar-option.rs-btn-active.focus {
  color: darken($theme-second-color, 20%);
}
.rs-picker-toolbar-option.rs-btn-disabled:hover,
.rs-picker-toolbar-option.rs-btn-disabled:focus {
  color: darken($theme-second-color, 20%);
  text-decoration: none;
}
.rs-picker-toolbar-option:not(:last-child) {
  position: relative;
}
.rs-picker-toolbar-option:not(:last-child)::before {
  content: '';
  width: 0.1rem;
  height: 2rem;
  background-color: #e5e5ea;
  position: absolute;
  top: 0;
  right: 0;
}
.rs-picker-toolbar-option-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.rs-picker-toolbar-option-disabled,
.rs-picker-toolbar-option-disabled:hover,
.rs-picker-toolbar-option-disabled:focus,
.rs-picker-toolbar-option-disabled:active {
  text-decoration: none;
}
.rs-picker-toolbar-right-btn-ok {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.4rem;
  // line-height: 1.42857143;
  border-radius: 0;
  color: #fff;
  padding: 0.75rem 2rem;
  font-weight: 500;
  background-color: $theme-color;
}
.rs-picker-toolbar-right-btn-ok.rs-btn-ghost {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
.rs-picker-toolbar-right-btn-ok.rs-btn-icon {
  padding-left: 2.4rem;
  height: 3.6rem;
  position: relative;
}
.rs-btn-ghost.rs-picker-toolbar-right-btn-ok.rs-btn-icon {
  padding-left: 2.2rem;
}
.rs-picker-toolbar-right-btn-ok.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
  padding-left: 4.4rem;
}
.rs-picker-toolbar-right-btn-ok.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
  padding-left: 1.2rem;
  padding-right: 4.4rem;
}
.rs-picker-toolbar-right-btn-ok.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
  left: auto;
  right: 0;
}
.rs-picker-toolbar-right-btn-ok.rs-btn-icon > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 3.6rem;
  height: 3.6rem;
  padding: 0.8rem 0;
  font-size: 1.4rem;
  line-height: 1.42857143;
}
.rs-btn-ghost.rs-picker-toolbar-right-btn-ok.rs-btn-icon > .rs-icon {
  width: 3.4rem;
  height: 3.4rem;
  padding: 0.7rem 0;
}
.rs-picker-toolbar-right-btn-ok.rs-btn-icon > .rs-icon > svg {
  height: 1.4rem;
  vertical-align: middle;
}
.rs-picker-toolbar-right-btn-ok.rs-btn-icon-circle {
  width: 3.6rem;
  padding: 0.8rem;
}
.rs-picker-toolbar-right-btn-ok:hover,
.rs-picker-toolbar-right-btn-ok:focus {
  color: #575757;
  text-decoration: none;
}
.rs-picker-toolbar-right-btn-ok:active,
.rs-picker-toolbar-right-btn-ok.rs-btn-active {
  outline: 0;
  background-image: none;
}
.rs-picker-toolbar-right-btn-ok.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: $theme-second-color;
}
.rs-picker-toolbar-right-btn-ok.rs-btn-loading:hover,
.rs-picker-toolbar-right-btn-ok.rs-btn-loading:not(.rs-btn-disabled):hover,
.rs-picker-toolbar-right-btn-ok.rs-btn-loading:focus,
.rs-picker-toolbar-right-btn-ok.rs-btn-loading:active,
.rs-picker-toolbar-right-btn-ok.rs-btn-loading:hover:active {
  color: #fff;
  background-color: $theme-second-color;
}
.rs-picker-toolbar-right-btn-ok:focus,
.rs-picker-toolbar-right-btn-ok.rs-btn-focus {
  color: #fff;
  background-color: $theme-second-color;
}
.rs-picker-toolbar-right-btn-ok:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-picker-toolbar-right-btn-ok.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: $theme-second-color;
}
.rs-picker-toolbar-right-btn-ok:hover {
  color: #fff;
}
.rs-picker-toolbar-right-btn-ok:not(.rs-btn-disabled):hover {
  opacity: 0.75;
}
.rs-picker-toolbar-right-btn-ok:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: $theme-second-color;
}
.rs-picker-toolbar-right-btn-ok:active,
.rs-picker-toolbar-right-btn-ok.rs-btn-active,
.rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok {
  color: #fff;
  background-color: $theme-second-color;
}
.rs-picker-toolbar-right-btn-ok:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-picker-toolbar-right-btn-ok.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: $theme-second-color;
}
.rs-picker-toolbar-right-btn-ok:active:hover,
.rs-picker-toolbar-right-btn-ok.rs-btn-active:hover,
.rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok:hover,
.rs-picker-toolbar-right-btn-ok:active:focus,
.rs-picker-toolbar-right-btn-ok.rs-btn-active:focus,
.rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok:focus,
.rs-picker-toolbar-right-btn-ok:active.focus,
.rs-picker-toolbar-right-btn-ok.rs-btn-active.focus,
.rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok.focus {
  color: #fff;
  background-color: $theme-second-color;
}
.rs-picker-toolbar-right-btn-ok:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-picker-toolbar-right-btn-ok.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-picker-toolbar-right-btn-ok:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-picker-toolbar-right-btn-ok.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-picker-toolbar-right-btn-ok:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-picker-toolbar-right-btn-ok.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
.rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
  background: $theme-second-color;
}
.rs-picker-toolbar-right-btn-ok:active,
.rs-picker-toolbar-right-btn-ok.rs-btn-active,
.open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok {
  background-image: none;
}
.rs-picker-toolbar-right-btn-ok.rs-btn-disabled:hover,
.rs-picker-toolbar-right-btn-ok.rs-btn-disabled:focus,
.rs-picker-toolbar-right-btn-ok.rs-btn-disabled.focus {
  background: $theme-second-color;
}
.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled,
.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled:hover,
.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled:hover:active {
  background-color: $theme-second-color;
}

.rs-picker-date-inline {
  height: 30rem;
}
.rs-picker-date-inline .rs-calendar {
  height: 28.6rem;
}
.rs-picker-menu .rs-calendar {
  width: 28rem;
  display: block;
  margin: 0 auto;
}
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content,
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  width: 3rem;
  height: 3rem;
}
.rs-picker-menu .rs-calendar .rs-calendar-table-header-row .rs-calendar-table-cell-content {
  height: 2.4rem;
  padding-top: 0;
}
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  padding-left: 0;
  padding-right: 0;
  display: inline-block;
}
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-scroll {
  height: 22.7rem;
}
.rs-picker-menu .rs-calendar .rs-calendar-time-dropdown-column > ul {
  height: 21.4rem;
  padding-bottom: 18.4rem;
}

.rs-picker-daterange-menu .rs-calendar {
  display: inline-block;
  width: 25.5rem;
  height: 27.8rem;
  padding-bottom: 1.2rem;
}
.rs-picker-daterange-menu .rs-calendar:first-child {
  border-right: 0.1rem solid #e5e5ea;
}
.rs-picker-daterange-menu .rs-calendar-header {
  width: 100%;
  text-align: center;
  padding-bottom: 1rem;
}
.rs-picker-daterange-menu .rs-calendar-header-month-toolbar {
  float: none;
}
.rs-picker-daterange-menu .rs-calendar-month-dropdown {
  z-index: 1;
}
.rs-picker-daterange-menu .rs-calendar-month-dropdown-list {
  width: 18.5rem;
}
.rs-picker-daterange-menu .rs-picker-toolbar {
  margin-top: 0.4rem;
}
.rs-picker-daterange-menu .rs-picker-daterange-calendar-single .rs-calendar {
  border: 0;
  display: block;
  margin: auto;
}
.rs-picker-daterange-header {
  padding: 0.8rem 1.2rem;
  font-size: 1.4rem;
  line-height: 1.42857143;
  border-bottom: 0.1rem solid #e5e5ea;
  display: none;
}
.rs-picker-daterange-calendar-group {
  height: 27.4rem;
  min-width: 51rem;
}
.rs-divider {
  background-color: #e5e5ea;
}
.rs-divider-vertical {
  display: inline-block;
  height: 1em;
  width: 0.1rem;
  vertical-align: middle;
  margin: 0 1.2rem;
}
.rs-divider-horizontal {
  height: 0.1rem;
  margin: 2.4rem 0;
}
.rs-divider-horizontal.rs-divider-with-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: transparent;
  margin: 3rem 0;
  display: table\9;
  text-align: center\9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-divider-horizontal.rs-divider-with-text {
    text-align: center\9;
  }
}
.rs-divider-horizontal.rs-divider-with-text::before,
.rs-divider-horizontal.rs-divider-with-text::after {
  content: '';
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  border-top: 0.1rem solid #e5e5ea;
  position: relative\9;
  display: table-cell\9;
  top: 50%\9;
  width: 50%\9;
  -webkit-transform: translateY(50%)\9;
  transform: translateY(50%)\9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-divider-horizontal.rs-divider-with-text::before,
  .rs-divider-horizontal.rs-divider-with-text::after {
    position: relative\9;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-divider-horizontal.rs-divider-with-text::before,
  .rs-divider-horizontal.rs-divider-with-text::after {
    top: 50%\9;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-divider-horizontal.rs-divider-with-text::before,
  .rs-divider-horizontal.rs-divider-with-text::after {
    width: 50%;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-divider-horizontal.rs-divider-with-text::before,
  .rs-divider-horizontal.rs-divider-with-text::after {
    -webkit-transform: translateY(50%)\9;
    transform: translateY(50%)\9;
  }
}
.rs-divider-inner-text {
  padding: 0 1.2rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.rs-picker-toggle-wrapper {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: 100%;
}
.rs-picker-toggle.rs-btn .rs-ripple-pond {
  display: none !important;
}
.rs-picker-block {
  display: block;
}
.rs-picker-disabled {
  opacity: 0.3;
}
.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #8e8e93;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: darken($theme-second-color, 20%);
}
.rs-picker-none {
  padding: 0.6rem 1.2rem 1.2rem;
  color: #8e8e93;
  cursor: default;
}
.rs-picker-countable .rs-picker-toggle-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.rs-picker-value-list {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block\9;
  max-width: 100%\9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-picker-value-list {
    max-width: 100%;
  }
}
.rs-picker-value-count {
  margin: 0 0.4rem;
  background: $theme-second-color;
  color: #fff;
  border-radius: 1rem;
  padding: 0 0.8rem;
  line-height: 2rem;
}
.rs-picker-value-separator {
  margin: 0 0.4rem 0 0;
}
.rs-picker-toggle {
  display: inline-block;
  width: 24rem;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: 0 !important;
  white-space: nowrap;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.4rem;
  border-radius: 0;
}
.rs-picker-toggle.rs-btn-ghost {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
.rs-picker-toggle.rs-btn-icon {
  padding-left: 2.4rem;
  height: 3.6rem;
  position: relative;
}
.rs-btn-ghost.rs-picker-toggle.rs-btn-icon {
  padding-left: 2.2rem;
}
.rs-picker-toggle.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
  padding-left: 4.4rem;
}
.rs-picker-toggle.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
  padding-left: 1.2rem;
  padding-right: 4.4rem;
}
.rs-picker-toggle.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
  left: auto;
  right: 0;
}
.rs-picker-toggle.rs-btn-icon > .rs-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 3.6rem;
  height: 3.6rem;
  padding: 0.8rem 0;
  font-size: 1.4rem;
  line-height: 1.42857143;
}
.rs-btn-ghost.rs-picker-toggle.rs-btn-icon > .rs-icon {
  width: 3.4rem;
  height: 3.4rem;
  padding: 0.7rem 0;
  border: 0.1rem solid $theme-second-color;

}
.rs-picker-toggle.rs-btn-icon > .rs-icon > svg {
  height: 1.4rem;
  vertical-align: middle;
}
.rs-picker-toggle.rs-btn-icon-circle {
  width: 3.6rem;
  padding: 0.8rem;
}
.rs-picker-toggle:hover,
.rs-picker-toggle:focus {
  color: #575757;
  text-decoration: none;
}
.rs-picker-toggle:active,
.rs-picker-toggle.rs-btn-active {
  outline: 0;
  background-image: none;
}
.rs-picker-default .rs-picker-toggle {

}
.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle {
  -webkit-transition: border-color ease-in-out 0.3s;
  transition: border-color ease-in-out 0.3s;
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  border-color: darken($theme-second-color, 20%);
}

/* stylelint-disable-next-line */
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
  padding-right: 3.2rem;
}
.rs-picker-cleanable .rs-picker-toggle.rs-btn {
  padding-right: 4.4rem;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 0.8rem;
}
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 0.7rem;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  left: 1rem;
}
.rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  right: 3rem;
}
.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 0.7rem 1.2rem;
  font-size: 1.4rem;
  line-height: 1.42857143;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper {
  padding-bottom: 0.5rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 0.5rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 1.2rem;
  margin-top: 0.5rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-picker-search-input input {
  height: 10.8rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn,
.rs-picker-daterange .rs-picker-toggle.rs-btn {
  width: 24rem;
  height: 100%;
  // height: $button-height;
  padding-right: 2.8rem;
  padding-left: 3.4rem;
  border: none;
}
.rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn,
.rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn {
  padding-right: 2.8rem;
  padding-left: 3.4rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 0.7rem;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 0.8rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  left: 1.2rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  right: 0.8rem;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
.rs-picker-toggle.rs-btn-lg {
  /* stylelint-disable */
  padding: 1rem 1.6rem;
  font-size: 1.6rem;
  line-height: 1.375;
  border-radius: 0.5rem;
  /* stylelint-enable */
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-lg {
  padding-right: 3.6rem;
}
.rs-picker-cleanable .rs-picker-toggle.rs-btn-lg {
  padding-right: 5rem;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  top: 1rem;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  top: 0.9rem;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  right: 1.6rem;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  right: 3.2rem;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-left: 10.5rem;
}
.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 0.9rem 1.6rem;
  font-size: 1.6rem;
  line-height: 1.375;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper {
  padding-bottom: 0.7rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 0.7rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 1.6rem;
  margin-top: 0.7rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-picker-search-input input {
  height: 2rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg {
  padding-right: 40.2rem;
}
.rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn-lg,
.rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn-lg {
  padding-right: 5rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  top: 1rem;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  top: 0.9rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  right: 1.6rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  right: 3.6rem;
}
.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
}
.rs-picker-toggle.rs-btn-lg .rs-picker-value-count {
  line-height: 2.2rem;
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-md {
  padding-right: 3.2rem;
}
.rs-picker-cleanable .rs-picker-toggle.rs-btn-md {
  padding-right: 4.4rem;
}
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  top: 0.8rem;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  top: 0.7rem;
}
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  right: 1.2rem;
}
.rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  right: 3rem;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md {
  padding-left: 10.1rem;
}
.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 0.7rem 1.2rem;
  font-size: 1.4rem;
  line-height: 1.42857143;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper {
  padding-bottom: 0.5rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 0.5rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 1.2rem;
  margin-top: 0.5rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-picker-search-input input {
  height: 10.8rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md {
  padding-right: 3.6rem;
}
.rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn-md,
.rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn-md {
  padding-right: 40.2rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  top: 0.8rem;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  top: 0.7rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
  right: 1.2rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
  right: 3rem;
}
.rs-picker-default .rs-picker-toggle.rs-btn-md {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
.rs-picker-toggle.rs-btn-sm {
  /* stylelint-disable */
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  line-height: 1.42857143;
  border-radius: 0.5rem;
  /* stylelint-enable */
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-sm {
  padding-right: 3rem;
}
.rs-picker-cleanable .rs-picker-toggle.rs-btn-sm {
  padding-right: 40.2rem;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  top: 0.5rem;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  top: 0.4rem;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  right: 1rem;
}
.rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  right: 20.8rem;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  padding-left: 0.9rem;
}
.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 0.4rem 1rem;
  font-size: 1.4rem;
  line-height: 1.42857143;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper {
  padding-bottom: 0.2rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 0.2rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 1rem;
  margin-top: 0.2rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-picker-search-input input {
  height: 10.8rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
  padding-right: 3.4rem;
}
.rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn-sm,
.rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
  padding-right: 4rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  top: 0.4rem;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  top: 0.3rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
  right: 1rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
  right: 20.8rem;
}
.rs-picker-default .rs-picker-toggle.rs-btn-sm {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.rs-picker-toggle.rs-btn-xs {
  padding: 0.2rem 0.8rem;
  font-size: 1.2rem;
  line-height: 1.66666667;
  border-radius: 0.5rem;
}
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-xs {
  padding-right: 20.8rem;
}
.rs-picker-cleanable .rs-picker-toggle.rs-btn-xs {
  padding-right: 4rem;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  top: 0.2rem;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  top: 0.1rem;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  right: 0.8rem;
}
.rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  right: 2.6rem;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs {
  padding-left: 0.7rem;
}
.rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding: 0.1rem 0.8rem;
  font-size: 1.2rem;
  line-height: 1.66666667;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
  padding-bottom: -0.1rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: -0.1rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-left: 0.8rem;
  margin-top: -0.1rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input input {
  height: 10.8rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
  padding-bottom: 0.1rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-tag {
  margin-top: 0.1rem;
}
.rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs {
  padding-right: 3rem;
}
.rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn-xs,
.rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn-xs {
  padding-right: 3.6rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  top: 0.2rem;
}
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  top: 0.1rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
  right: 0.8rem;
}
.rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  right: 2.4rem;
}
.rs-picker-default .rs-picker-toggle.rs-btn-xs {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
/* stylelint-disable-next-line */
.rs-picker-toggle {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 3.2rem;
  display: inline-block;
  outline: none;
  cursor: pointer;
  color: #575757;
  border: none;
}
.rs-picker-default .rs-picker-toggle-custom-title {
  padding: 0 !important;
}
.rs-picker-disabled .rs-picker-toggle {
  cursor: not-allowed;
}
.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):hover,
.rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):focus {
  // border-color: darken($theme-second-color, 20%);
}
.rs-picker-subtle .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 3.2rem;
  display: inline-block;
  outline: none;
  cursor: pointer;
}
.rs-picker-subtle .rs-picker-toggle-custom-title {
  padding: 0 !important;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle {
  cursor: not-allowed;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active {
  background: none;
}
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover::after,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus::after,
.rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active::after {
  display: none;
}
.rs-picker-toggle-value {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.rs-picker-cleanable .rs-picker-toggle {
  padding-right: 4.4rem;
}
.rs-picker-date.rs-picker-cleanable .rs-picker-toggle,
.rs-picker-daterange.rs-picker-cleanable .rs-picker-toggle {
  padding-right: 50.2rem;
}
.rs-picker-toggle-clean {
  display: inline-block;
  margin-left: 0.2rem;
  position: absolute;
  top: 0.8rem;
  right: 3rem;
  font-weight: normal;
  background: inherit;
  color: #a6a6a6;
  -webkit-transition: 0.2s color linear;
  transition: 0.2s color linear;
  cursor: pointer;
  text-align: center\9;
}
.rs-picker-toggle-clean::before {
  font-family: 'rsuite-icon-font' !important;
}
.dropdown-placement-left-end .rs-picker-toggle-clean::before,
.dropdown-placement-left-start .rs-picker-toggle-clean::before {
  content: '\ea0a' /* rtl: "\ea0c" */;
}
.dropdown-placement-right-end .rs-picker-toggle-clean::before,
.dropdown-placement-right-start .rs-picker-toggle-clean::before {
  content: '\ea0c' /* rtl: "\ea0a" */;
}
.dropdown-placement-top-start .rs-picker-toggle-clean::before,
.dropdown-placement-top-end .rs-picker-toggle-clean::before {
  content: '\ea0e';
}
.dropdown-placement-bottom-start .rs-picker-toggle-clean::before,
.dropdown-placement-bottom-end .rs-picker-toggle-clean::before {
  content: '\ea08';
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-picker-toggle-clean {
    text-align: center\9;
  }
}
.rs-picker-toggle-clean:hover {
  color: $theme-second-color;
}
.rs-picker-date .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle-clean {
  right: 30.8rem;
}
.rs-picker-toggle-caret {
  display: inline-block;
  position: absolute;
  top: 0.8rem;
  font-weight: normal;
  color: $theme-second-color;
}
.rs-picker-toggle-caret::before {
  font-family: 'feather' !important;
}
.rs-picker-placement-left-end .rs-picker-toggle-caret::before,
.rs-picker-placement-left-start .rs-picker-toggle-caret::before {
  content: '\e83a' /* rtl: "\ea0c" */;
}
.rs-picker-placement-right-end .rs-picker-toggle-caret::before,
.rs-picker-placement-right-start .rs-picker-toggle-caret::before {
  content: '\e83a' /* rtl: "\ea0a" */;
}
.rs-picker-placement-top-start .rs-picker-toggle-caret::before,
.rs-picker-placement-top-end .rs-picker-toggle-caret::before {
  content: '\e83a';
}
.rs-picker-placement-bottom-start .rs-picker-toggle-caret::before,
.rs-picker-placement-bottom-end .rs-picker-toggle-caret::before {
  content: '\e83a';
}
.rs-picker-toggle-caret::before {
  content: "\e83a";
}
.rs-picker-menu {
  position: absolute;
  text-align: left;
  z-index: 9999;
  border-radius: 0;
  background-color: #fff;
  -webkit-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.06), 0 0.4rem 0.4rem rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.06), 0 0.4rem 0.4rem rgba(0, 0, 0, 0.12);
  overflow: hidden;
  -webkit-transition: none;
  transition: none;
  margin-top: 0.5rem;
}
.rs-modal-open .rs-picker-menu {
  z-index: 1055;
}
.rs-drawer-open .rs-picker-menu {
  z-index: 1055;
}
.rs-picker-menu.rs-picker-select-menu,
.rs-picker-menu.rs-picker-check-menu {
  padding-top: 0.6rem;
}
.rs-picker-menu .rs-picker-search-bar {
  position: relative;
  padding: 0.6rem 1.2rem 1.2rem;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  display: block;
  width: 100%;
  color: #575757;
  background-color: #fff;
  background-image: none;
  border: 0.1rem solid #e5e5ea;
  -webkit-transition: border-color ease-in-out 0.3s;
  transition: border-color ease-in-out 0.3s;
  border-radius: 0;
  padding: 0.7rem 10.1rem;
  font-size: 1.4rem;
  line-height: 1.42857143;
  height: 3.6rem;
  min-width: 20rem;
  padding-right: 3.6rem;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
    height: 3.6rem;
  }
}
textarea.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  height: auto;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::-webkit-input-placeholder {
  color: #8e8e93;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::-moz-placeholder {
  color: #8e8e93;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::-ms-input-placeholder {
  color: #8e8e93;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::placeholder {
  color: #8e8e93;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus,
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover {
  border-color: darken($theme-second-color, 20%);
  outline: 0;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input[disabled] {
  background-color: #f7f7fa;
  color: #c5c6c7;
  cursor: not-allowed;
}
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input[disabled]:hover {
  border-color: #e5e5ea;
}
.rs-picker-menu .rs-picker-search-bar::after {
  content: '\f002';
  font-family: 'rsuite-icon-font';
  font-style: normal;
  position: absolute;
  width: 1.4rem;
  color: #c5c6c7;
  font-size: 1.4rem;
  line-height: 1.42857143;
  top: 1.4rem;
  right: 2.4rem;
}
.rs-tree-menu.rs-picker-menu .rs-picker-search-bar::after,
.rs-check-tree-menu.rs-picker-menu .rs-picker-search-bar::after {
  top: 0.7rem;
}
.rs-picker-menu .ReactVirtualized__Grid.ReactVirtualized__List:focus {
  outline: none;
}

.rs-picker-default .rs-btn,
.rs-picker-input .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-picker-toggle {
  background: #fff !important;
}
