.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  text-decoration: none;
  border: 0.1rem solid transparent;
  padding: 0 1rem;
  font-size: $base-font-size;
  line-height: 1.5;
  border-radius: 0;
  height: $button-height;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  text-transform: uppercase;

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:visited {
    text-decoration: none;
  }

  &.disabled {
    opacity: 0.65;
  }

  &.btn-theme {
    color: $white-color;
    background: $theme-color;
    border: 0.1rem solid $theme-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      color: $white-color;
      background: $theme-color;
      border: 0.1rem solid $theme-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($theme-color, 50%);
    }
  }

  &.btn-secondary-theme {
    color: $white-color;
    background: $theme-second-color;
    border: 0.1rem solid $theme-second-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      color: $white-color;
      background: $theme-second-color;
      border: 0.1rem solid $theme-second-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($theme-second-color, 50%);
    }
  }

  &.btn-theme-outline {
    color: $black-color;
    border: 0.1rem solid $theme-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: $black-color;
      border: 0.1rem solid $theme-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($theme-color, 50%);
    }
  }

  &.btn-primary {
    padding-right: 0;
    background: $white-color;
    border: 0.1rem solid $theme-color;
    color: $black-color;

    &::after {
      content: "\e844";
      font-family: "feather";
      background: $theme-color;
      height: 3.3rem;
      width: 3rem;
      color: white;
      font-size: 2.4rem;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;
    }

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active {
      color: $black-color;
      border: 0.1rem solid $theme-color;
      opacity: 0.75;
      background-color: $white-color;
      box-shadow: 0 0 0 0.2rem lighten($theme-color, 50%);
    }

    &:disabled {
      border-color: $theme-second-color;

      &::after {
        background-color: $theme-second-color;
      }
    }
  }

  &.btn-primary-outline {
    color: $primary-color;
    border: 0.1rem solid $primary-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: $primary-color;
      border: 0.1rem solid $primary-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($primary-color, 50%);
    }
  }

  &.btn-danger {
    color: $white-color;
    background: $danger-color;
    border: 0.1rem solid $danger-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      color: $white-color;
      background: $danger-color;
      border: 0.1rem solid $danger-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($danger-color, 50%);
    }
  }

  &.btn-danger-outline {
    color: $danger-color;
    border: 0.1rem solid $danger-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: $danger-color;
      border: 0.1rem solid $danger-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($danger-color, 50%);
    }
  }

  &.btn-info {
    color: $white-color;
    background: $info-color;
    border: 0.1rem solid $info-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      color: $white-color;
      background: $info-color;
      border: 0.1rem solid $info-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($info-color, 50%);
    }
  }

  &.btn-info-outline {
    color: $info-color;
    border: 0.1rem solid $info-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: $info-color;
      border: 0.1rem solid $info-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($info-color, 50%);
    }
  }

  &.btn-success {
    color: $white-color;
    background: $success-color;
    border: 0.1rem solid $success-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      color: $white-color;
      background: $success-color;
      border: 0.1rem solid $success-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($success-color, 50%);
    }
  }

  &.btn-success-outline {
    color: $success-color;
    border: 0.1rem solid $success-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: $success-color;
      border: 0.1rem solid $success-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($success-color, 50%);
    }
  }

  &.btn-warning {
    color: $white-color;
    background: $warning-color;
    border: 0.1rem solid $warning-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active,
    &:not(:disabled):not(.disabled):active{
      color: $white-color;
      background: $warning-color;
      border: 0.1rem solid $warning-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($warning-color, 50%);
    }
  }

  &.btn-warning-outline {
    color: $warning-color;
    border: 0.1rem solid $warning-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: $warning-color;
      border: 0.1rem solid $warning-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($warning-color, 50%);
    }
  }

  &.btn-no-outline {
    border: none;
    color: $grey-text-color;

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      opacity: 0.75;
    }
  }

  &.disabled,
  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }

  &.btn-small {
    height: 2.5rem;
    padding: 0 0.75rem;
    font-size: $small-font-size;
    line-height: 1;
  }

  &.btn-action {
    color: $btn-action-color;
    border: 0.1rem solid $btn-action-border-color;
    height: 3rem;

    .feather {
      margin-right: 0;
      font-size: $base-font-size;
    }

    &:hover,
    &:focus,
    &:active,
    &:hover:focus,
    &:hover:active,
    &:focus:active,
    &:hover:focus:active {
      color: $card-border-bottom-color;
      border: 0.1rem solid $card-border-bottom-color;
      opacity: 0.75;
      box-shadow: 0 0 0 0.2rem lighten($card-border-bottom-color, 50%);
    }
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .feather {
    margin-right: 1rem;

    &.custom-icon {
      margin-right: 0;
      font-size: $m-font-size;
    }
  }
}
