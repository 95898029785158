// toDo PPP, quickfix for panel form

.input-group {
  margin-bottom: 0.75rem!important;
  .form-label {
    margin-bottom: 0.5rem;

    ~ input {
      height: 4rem!important;
    }
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;
  padding: 0 1.5rem;
  width: 100%;
}
