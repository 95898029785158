

.checkbox-container {
  display: flex;
  width: auto;

  .checkbox-wrapper {
    background-color: $checkbox-background;
    padding: 1rem 2rem;
    margin: .5rem;
    display: block;
    width: 8rem;
    text-align: center;
    font-weight: 700;
    color: $checkbox-color;
    font-size: 1.4rem;
    min-height: 1.7rem;
    &.checked {
      background-color: $checked-background;
      color: $checked-color;
    }
    .checkbox-input {
      display: none;
    }
  }
}
