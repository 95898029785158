.switch {
  position: relative;
  display: inline-block;
  width: 4.4rem;
  height: 2.2rem;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: $theme-color;
      border: 0.1rem solid $theme-color;
    }

    &:disabled + .slider {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(2.2rem);
    -ms-transform: translateX(2.2rem);
    transform: translateX(2.2rem);
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white-color;
    border: 0.1rem solid $btn-action-border-color;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 1.1rem;
  }

  .slider:before {
    position: absolute;
    top: 0.1rem;
    left: 0.1rem;
    content: "";
    height: 1.8rem;
    width: 1.8rem;
    background: $white-color;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    border: 0.1rem solid $btn-action-border-color;
    box-shadow: .1rem .1rem .3rem .1rem $datepicker-text-color;
  }

  &.sm {
      width: 3.8rem;
      height: 1.8rem;

      input:checked + .slider:before {
        -webkit-transform: translateX(1.9rem);
        -ms-transform: translateX(1.9rem);
        transform: translateX(1.9rem);
      }

      .slider:before {
        height: 1.4rem;
        width: 1.4rem;
      }
  }
}
