.color-theme {
  color: $theme-color;
}

.color-success {
  color: $success-color;
}

.color-primary {
  color: $primary-color;
}

.color-danger {
  color: $danger-color;
}

.color-warning {
  color: $warning-color;
}

.color-info {
  color: $info-color;
}

